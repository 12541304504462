import styled from '@emotion/styled';
import IconButtonComponent from '../../components/icon-button';
import { useState } from 'preact/hooks';
import Popover from '../../components/popover/popover';
import DeleteFileModal from './delete-file-modal';
import { PopupMenuItem } from '../../components/styled-components/typography';

const FileListItemPopupMenu = (props) => {

    const [popoverOpen, setPopoverOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const { asset, reload } = props;

    const { guid, name } = asset;

    const iconProps = {
        icon: 'more',
        style: 'transparent',
        onClick: () => {
            setPopoverOpen(!popoverOpen);
        },
    };

    const popoverContent = (
        <div>
            <PopupMenuItem onClick={() => setDeleteModalOpen(true)}>
                Delete
            </PopupMenuItem>
        </div>
    );

    const popoverProps = {
        visible: popoverOpen,
        content: popoverContent,
        onClose: () => {
            setPopoverOpen(false);
        },
    };

    const deleteModalProps = {
        visible: deleteModalOpen,
        title: 'Confirm deletion',
        closeModal: () => setDeleteModalOpen(false),
        reload,
        guid,
        name,
    };

    return (
        <Popover { ...popoverProps }>
            <IconButtonComponent { ...iconProps } />
            <DeleteFileModal { ...deleteModalProps } />
        </Popover>
    );
};

export default FileListItemPopupMenu;

import React from 'react';
import styled from '@emotion/styled';

const ToggleContainer = styled.label`
  display: inline-block;
  position: relative;
  width: 54px;
  height: 28px;
`;

const SliderInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + .slider {
    background-color: #4d5eab;
  }

  &:focus + .slider {
    box-shadow: 0 0 1px #4d5eab;
  }

  &:checked + .slider:before {
    transform: translateX(26px);
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const SliderToggle = ({ isChecked, onClick }) => {
  return (
    <ToggleContainer>
      <SliderInput type='checkbox' checked={isChecked} onClick={onClick ? onClick : () => {}} />
      <Slider className='slider' />
    </ToggleContainer>
  );
};

export default SliderToggle;

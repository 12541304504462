import styled from '@emotion/styled';
import logo from '../../assets/logo.png';

const StyledLogo = styled.img`
    height: 48px;
    width: 48px;
    margin-right: 0.5rem;
`;

const Logo = () => (
    <StyledLogo src={logo} />
);

export default Logo;
import { useState } from 'preact/hooks';
import styled from '@emotion/styled';
import Popover from '../../../components/popover/popover';
import IconButtonComponent from '../../../components/icon-button';
import { colors } from '../../../style/style-variables';
import { PopupMenuItem } from '../../../components/styled-components/typography';

const PlaylistPopupMenu = ({ name, guid, reload, handleRenameClick, onEditClick }) => {
    const [popoverOpen, setPopoverOpen] = useState(false);

    const popoverContent = (
        <div>
            <PopupMenuItem onClick={() => onEditClick()}>
                Edit playlist info
            </PopupMenuItem>
        </div>
    );

    const iconProps = {
        icon: 'more',
        style: 'transparent',
        onClick: () => {
            setPopoverOpen(!popoverOpen);
        },
    };

    const popoverProps = {
        visible: popoverOpen,
        content: popoverContent,
        onClose: () => {
            setPopoverOpen(false);
        },
    };

    return (
        <Popover { ...popoverProps }>
            <IconButtonComponent { ...iconProps } />
        </Popover>
    );
};

export default PlaylistPopupMenu;
import { useState } from 'preact/hooks';
import styled from '@emotion/styled';

const ToolTipWrapper = styled.div`
  & {
    --tooltip-text-color: white;
    --tooltip-background-color: black;
    --tooltip-margin: 30px;
    --tooltip-arrow-size: 6px;
    position: relative;
  }

  .Tooltip-Tip {
    position: absolute;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px;
    color: var(--tooltip-text-color);
    background: var(--tooltip-background-color);
    font-size: 14px;
    font-family: sans-serif;
    line-height: 1;
    z-index: 100;
    white-space: nowrap;
  }

  .Tooltip-Tip::before {
    content: ' ';
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: var(--tooltip-arrow-size);
    margin-left: calc(var(--tooltip-arrow-size) * -1);
  }

  .Tooltip-Tip.top {
    top: calc(calc(var(--tooltip-margin) * -1) - ${(props) => props.position});
  }

  .Tooltip-Tip.top::before {
    top: 100%;
    border-top-color: var(--tooltip-background-color);
  }

  /* Absolute positioning */
  .Tooltip-Tip.right {
    left: calc(var(--tooltip-margin) + ${(props) => props.position});
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }

  .Tooltip-Tip.right::before {
    left: calc(var(--tooltip-arrow-size) * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: var(--tooltip-background-color);
  }

  .Tooltip-Tip.bottom {
    bottom: calc(calc(var(--tooltip-margin) * -1) - ${(props) => props.position});
  }

  .Tooltip-Tip.bottom::before {
    bottom: 100%;
    border-bottom-color: var(--tooltip-background-color);
  }

  .Tooltip-Tip.left {
    left: auto;
    right: calc(var(--tooltip-margin) + ${(props) => props.position});
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }

  .Tooltip-Tip.left::before {
    left: auto;
    right: calc(var(--tooltip-arrow-size) * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: var(--tooltip-background-color);
  }
`;

const Tooltip = ({ delay = 400, children, content = null, direction = 'top', position = '0px'}) => {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  const conditions = ['px', '%', 'rem', 'vw', 'vh'];

  return (
    <ToolTipWrapper
      className="Tooltip-Wrapper"
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      position={!conditions.some((condition) => position.includes(condition)) ? position.concat('px') : position}
    >
      {children}
      {active && <div className={`Tooltip-Tip ${direction || 'top'}`}>{content}</div>}
    </ToolTipWrapper>
  );
};

export default Tooltip;

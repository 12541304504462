import styled from '@emotion/styled';
import { useContext, useEffect, useMemo, useState } from 'preact/hooks';
import PropTypes from 'prop-types';

import { Select } from '../../components/form';
import OrganizationAddModal from './OrganizationModal';
import Button from '../../components/button';
import MainContent from '../../components/main-content/main-content';
import { UserModal } from './UserModal';
import ConfirmDeleteUserModal from './ConfirmDeleteUserModal';
import AssignRoleModal from './AssignRoleModal';
import Table from '../../components/table/Table';
import { PageHeading } from '../../components/styled-components/typography';
import { FlexRowSpaceBetween, Space } from '../../components/styled-components/layout';
import { colors } from '../../style/style-variables';
import { AppContext } from '../../app-context/app-context';
import { useCreateOrganization, useGetOrganizations } from '../../hooks/organization.hooks';
import { useGetUsers, useSetUserOrganization, useSetUserRole } from '../../hooks/user.hooks';
import { isActionAllowed } from '../../helpers/roles';
import { PERMISSIONS, ROLES } from '../../constants';
import { useGetRoles } from '../../hooks/role.hooks';
import Icons from '../../components/icons';
import OrganizationCardPopoverMenu from './OptionMenu';

const RemoveButton = styled.button`
  all: unset;
  &:hover {
    font-weight: bold;
    cursor: pointer;
    color: ${colors['error']};
  }
`;

const Management = () => {
  const { setMenuVisible, user } = useContext(AppContext);
  const [visible, setVisible] = useState(false);
  const [roleModal, setRoleModal] = useState({
    userName: undefined,
    userId: undefined,
    roleId: undefined,
    isVisible: false,
  });
  const [deleteUserModal, setDeleteUserModal] = useState({
    userEmail: undefined,
    isVisible: false,
  });
  const [userModalIsVisible, setUserModalIsVisible] = useState(false);

  const isAllowedToSetOrganization = isActionAllowed(
    PERMISSIONS.SET_USER_ORGANIZATION,
    user?.role_id,
  );

  const {
    data: organizations = [],
    isLoading: isLoadingOrganization,
    refetch: refetchOrganizations,
  } = useGetOrganizations(isAllowedToSetOrganization);
  const { data: roles = [] } = useGetRoles();
  const { data: users = [], isLoading: loadingUsers } = useGetUsers();

  const { mutate: mutateSetUserOrganization } = useSetUserOrganization();
  const { mutate: mutateOrganization } = useCreateOrganization();
  const { mutate: setUserRole } = useSetUserRole();

  useEffect(() => {
    setMenuVisible(true);
  }, []);

  const handleCreateOrganization = (variables) => {
    mutateOrganization(variables.name);
  };

  const handleSetUserOrganization = async ({ userId, organizationId }) => {
    mutateSetUserOrganization({ userId, organizationId });
    refetchOrganizations();
  };

  const handleCloseRoleModal = () => {
    setRoleModal({
      userName: undefined,
      userId: undefined,
      roleId: undefined,
      isVisible: false,
    });
  };

  const handleSetUserRole = async () => {
    setUserRole({ userId: roleModal.userId, roleId: roleModal.roleId });
    handleCloseRoleModal();
  };

  const ConditionalRemoveButton = ({ targetUserEmail, targetUserRoleId }) => {
    const userCanDeleteUser = isActionAllowed(PERMISSIONS.DELETE_USER, user?.role_id);
    const isSelf = user?.email === targetUserEmail;
    const isNotAllowedToDeleteAdmin =
      targetUserRoleId === ROLES.ADMIN && user?.role_id !== ROLES.ADMIN;

    if (isSelf) {
      return null;
    }

    if (isNotAllowedToDeleteAdmin) {
      return null;
    }

    if (!userCanDeleteUser) {
      return null;
    }

    return (
      <RemoveButton
        onClick={() => {
          setDeleteUserModal({
            userEmail: targetUserEmail,
            isVisible: true,
          });
        }}
      >
        <Icons
          name='trash'
          style={{
            color: 'inherit',
          }}
        ></Icons>
      </RemoveButton>
    );
  };

  ConditionalRemoveButton.propTypes = {
    targetUserEmail: PropTypes.string.isRequired,
    targetUserRoleId: PropTypes.number.isRequired,
  };

  const userColumns = [
    { Header: 'First name', accessor: 'firstName' },
    { Header: 'Last name', accessor: 'lastName' },
    { Header: 'Email', accessor: 'email' },
    { Header: 'Organization uuid', accessor: 'organizationUuid' },
    { Header: 'Last logged-in', accessor: 'lastLoggedIn' },
    { Header: '', accessor: 'remove' },
  ];

  const userCanAssignOrganization = isActionAllowed(
    PERMISSIONS.SET_USER_ORGANIZATION,
    user?.role_id,
  );
  const userCanAssignRole = isActionAllowed(PERMISSIONS.SET_USER_ROLE, user?.role_id);

  if (userCanAssignOrganization) {
    userColumns.splice(userColumns.length - 2, 0, {
      Header: 'Organization',
      accessor: 'organization',
    });
  }

  if (userCanAssignRole) {
    userColumns.splice(userColumns.length - 2, 0, { Header: 'Role', accessor: 'role' });
  }

  const organizationsOptions = organizations.map((organization) => ({
    key: organization.uuid,
    name: organization.name,
  }));

  const rolesOptions = roles.map((role) => ({
      key: role.id,
      name: role.name,
    }));

  const userData = useMemo(
    () =>
      users.map(
        ({ uuid, email, firstName, lastName, organizationUuid, role_id, lastLoggedIn }) => ({
          email,
          firstName,
          lastName,
          organizationUuid,
          organization: userCanAssignOrganization && (
            <Select
              value={organizationUuid}
              options={[{ key: null, name: null }, ...organizationsOptions]}
              onChange={(event) => {
                handleSetUserOrganization({ userId: uuid, organizationId: event.target.value });
              }}
            />
          ),
          role: userCanAssignRole && (
            <Select
              value={role_id}
              options={rolesOptions}
              onChange={(event) => {
                setRoleModal({
                  userName: email,
                  userId: uuid,
                  roleId: Number(event.target.value),
                  isVisible: true,
                });
              }}
            />
          ),
          lastLoggedIn: lastLoggedIn && new Date(lastLoggedIn).toLocaleString('sv-SE').slice(0, -3),
          remove: (
            <div
              style={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <ConditionalRemoveButton targetUserEmail={email} targetUserRoleId={role_id} />
            </div>
          ),
        }),
      ),
    [users, organizationsOptions, rolesOptions],
  );

  const organizationColumns = [
    { Header: 'Organization name', accessor: 'organizationName' },
    { Header: 'Number of users', accessor: 'users' },
    { Header: '', accessor: 'options' },
  ];

  const organizationData = useMemo(
    () =>
      organizations.map(({ name, users, uuid }) => ({
        organizationName: name,
        users: `${users.length} users`,
        options: (
          <div
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <OrganizationCardPopoverMenu name={name} uuid={uuid} />
          </div>
        ),
      })),
    [organizations],
  );

  return (
    <MainContent>
      {isActionAllowed(PERMISSIONS.GET_ORGANIZATIONS, user?.role_id) && (
        <>
          <FlexRowSpaceBetween>
            <PageHeading>Organizations</PageHeading>
            {isActionAllowed(PERMISSIONS.CREATE_ORGANIZATION, user?.role_id) && (
              <Button text='Create organization' icon='add' onClick={() => setVisible(true)} />
            )}
          </FlexRowSpaceBetween>
          <Space />
          <Table
            columns={organizationColumns}
            data={organizationData}
            isLoading={isLoadingOrganization}
            sortable
          />
          <OrganizationAddModal
            visible={visible}
            onSubmit={handleCreateOrganization}
            closeModal={() => {
              setVisible(false);
            }}
          />
          <Space />
        </>
      )}
      {isActionAllowed(PERMISSIONS.GET_USERS, user?.role_id) && (
        <>
          <FlexRowSpaceBetween>
            <PageHeading>Users</PageHeading>
            {isActionAllowed(PERMISSIONS.CREATE_USER, user?.role_id) && (
              <Button text='Create user' icon='add' onClick={() => setUserModalIsVisible(true)} />
            )}
          </FlexRowSpaceBetween>
          <Space />
          <Table columns={userColumns} data={userData} isLoading={loadingUsers} sortable />
          <ConfirmDeleteUserModal
            isVisible={deleteUserModal.isVisible}
            userEmail={deleteUserModal.userEmail}
            onClose={() => {
              setDeleteUserModal({
                userEmail: undefined,
                isVisible: false,
              });
            }}
            onSubmit={() => {
              setDeleteUserModal({
                userEmail: undefined,
                isVisible: false,
              });
            }}
          />

          <AssignRoleModal
            isVisible={roleModal.isVisible}
            onClose={handleCloseRoleModal}
            onSubmit={handleSetUserRole}
            newRoleId={roleModal.roleId}
            userName={roleModal.userName}
          />

          <UserModal
            isVisible={userModalIsVisible}
            onClose={() => {
              setUserModalIsVisible(false);
            }}
            onSubmit={() => {
              setUserModalIsVisible(false);
            }}
          />
        </>
      )}
    </MainContent>
  );
};

export default Management;

import { useContext, useState } from 'preact/hooks';
import { Link } from 'preact-router/match';
import { css } from '@emotion/css';
import axios from 'axios';
import ButtonComponent from '../../../components/button';
import { Input } from '../../../components/form';
import { SectionHeading, Text } from '../../../components/styled-components/typography';
import { FlexRowSpaceBetween, Space } from '../../../components/styled-components/layout';
import { AppContext } from '../../../app-context/app-context';
import { colors } from '../../../style/style-variables';
import PropTypes from 'prop-types';
import { getErrorMessage } from '../../../helpers/get-error-message';

const linkStyle = css`
  font-family: roboto;
  color: ${colors.lightGray};
  font-weight: 300;
  font-size: 0.8rem;
  display: block;
  &.active {
    background-color: ${colors['blue']};
  }
`;

const Login = ({ setState, email, setEmail, password, setPassword, setSession }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setUser } = useContext(AppContext);

  const emailInputProps = {
    value: email,
    label: 'Email Address',
    onChange: (event) => setEmail(event.target.value),
    type: 'email',
  };

  const passwordInputProps = {
    value: password,
    label: 'Password',
    onChange: (event) => setPassword(event.target.value),
    type: 'password',
  };

  const buttonProps = {
    onClick: async () => {
      try {
        setLoading(true);
        const { data } = await axios.post('/login', { email: email.toLowerCase(), password });

        setLoading(false);

        if (data.status === 'OK') {
          // this will trigger a reroute to the logged in area
          setUser({
            ...data.user,
          });
        } else {
          setState(data.status);
          setSession(data.session);
        }
      } catch (error) {
        setError(getErrorMessage(error));

        setLoading(false);
      }
    },
    disabled: !email || !password || password.length < 6,
    loading,
    text: 'sign in',
  };

  return (
    <>
      <SectionHeading color='superLightGray'>Interactive Content Solution Cloud</SectionHeading>
      {error && (
        <>
          <Space size={1} />
          <Text color='error'>{error}</Text>
          <Space size={1} />
        </>
      )}
      <Input {...emailInputProps} />
      <Space size={1} />
      <Input {...passwordInputProps} />
      <Space size={2} />
      <FlexRowSpaceBetween>
        <ButtonComponent {...buttonProps} />
        <Link class={linkStyle} activeClassName='active' href='/forgot-password'>
          Forgot password?
        </Link>
      </FlexRowSpaceBetween>
      <Space size={1} />
    </>
  );
};

Login.propTypes = {
  setState: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
  setSession: PropTypes.func.isRequired,
};

export default Login;

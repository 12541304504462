import styled from '@emotion/styled';
import { colors } from '../../style/style-variables';

export const StyledHeader = styled.div`
    padding: .5rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${ colors.lightBlack };
    position: relative;
    z-index: 1;
    position: fixed;
    width: 100%;
    height: 70px;
`;

export const StyledLeft = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledRight = styled.div`
    display: flex;
    align-items: center;
`;

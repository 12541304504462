import { useState, useCallback } from 'preact/hooks';
import { Calendar as ReactCalendar, dateFnsLocalizer } from 'react-big-calendar';
import { sv } from 'date-fns/locale';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { StyledCalendar } from './react-big-calendar.style';

const locales = {
  'se-SV': sv,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const DragAndDropCalendar = withDragAndDrop(ReactCalendar);

const VIEW = {
  WEEK: 'week',
  MONTH: 'month',
};

const Calendar = ({ events, onSelectEvent = () => null, onSelectSlot = () => {}, onEventDrop, onEventResize }) => {
  const [calendarEvents, setCalendarEvents] = useState(events || []);
  const [view, setView] = useState(localStorage.getItem('calendarView') || VIEW.MONTH);

  const updateEventDates = useCallback(
    ({ event, start, end }) => {
      let updatedEvent = {};

      setCalendarEvents((state) => {
        const targetedEvent = state.find((ev) => ev.id === event.id) ?? {};
        const rest = state.filter((ev) => ev.id !== event.id);

        updatedEvent = {
          ...targetedEvent,
          start: new Date(start.getTime() - start.getTimezoneOffset() * 60 * 1000),
          end: new Date(end.getTime() - end.getTimezoneOffset() * 60 * 1000),
        };

        return [
          ...rest,
          {
            ...updatedEvent,
          },
        ];
      });

      return updatedEvent;
    },
    [setCalendarEvents]
  );

  const handleMoveEvent = (moveEventData) => {
    const updatedEvent = updateEventDates(moveEventData);

    if (!!onEventDrop) {
      onEventDrop(updatedEvent);
    }
  };

  const handleResizeEvent = (resizeEventData) => {
    const updatedEvent = updateEventDates(resizeEventData);

    if (!!onEventResize) {
      onEventResize(updatedEvent);
    }
  };

  const handleSetCalendarView = (selectedView) => {
    localStorage.setItem('calendarView', selectedView);

    setView(selectedView);
  };

  const isAssetCollidingWithPlaylist = (assetStart, playlistStart, playlistEnd) => {
    if (assetStart > playlistStart && assetStart < playlistEnd) {
      return true;
    }

    return false;
  };

  const eventPropGetter = useCallback((event) => {
    const playlists = events.filter((event) => event.type == 'playlist');

    const isColliding = playlists.some((playlist) =>
      isAssetCollidingWithPlaylist(event.start, playlist.start, playlist.end)
    );

    return {
      ...(isColliding && { style: { opacity: 1 } }),
      ...(event.isDraggable ? { className: 'isDraggable' } : { className: 'nonDraggable' }),
      ...(event.isResizable ? { className: 'isResizable' } : { className: 'nonResizable' }),
    };
  }, []);

  return (
    <StyledCalendar>
      <DragAndDropCalendar
        selectable
        popup
        resizable
        draggableAccessor="isDraggable"
        resizableAccessor="isResizable"
        eventPropGetter={eventPropGetter}
        onEventDrop={handleMoveEvent}
        onEventResize={handleResizeEvent}
        onSelectEvent={onSelectEvent}
        localizer={localizer}
        events={calendarEvents}
        view={view}
        onView={handleSetCalendarView}
        views={[VIEW.MONTH, VIEW.WEEK]}
        style={{ height: 800 }}
        onSelectSlot={onSelectSlot}
        // when we save timestamps, we save it in utc (ie we don't save 14:00-18:00 in the editors timezone, we save 14:00-18:00 in UTC)
        // here we convert back to the editors timezone when displaying the calendar
        startAccessor={(event) => {
          const start = new Date(event.start.getTime() + event.start.getTimezoneOffset() * 60 * 1000);
          return start;
        }}
        endAccessor={(event) => {
          const end = new Date(event.end.getTime() + event.end.getTimezoneOffset() * 60 * 1000);
          return end;
        }}
      />
    </StyledCalendar>
  );
};

export default Calendar;

export const getErrorMessage = (error) => {
  const unhandledError = 'Something went wrong. Please try again later.';

  try {
    if (
      !error ||
      !error?.response ||
      error?.response?.status >= 500 ||
      !error?.response?.data ||
      error?.response?.data?.toLowerCase() === 'unhandled'
    ) {
      return unhandledError;
    }

    return error.response.data;
  } catch (err) {
    return unhandledError;
  }
};

window.process = { env: { NODE_ENV: {}}};

import { render } from 'preact';
import { ClientContext } from '../graphql-context/graphql-context';
import { GraphQLClient } from 'graphql-request';

import App from '../features/app/app';
import { AppContextProvider } from '../app-context/app-context';

// Get the data from a script tag and parse it. This is much more efficient than
// passing the data as a real javascript object (https://www.youtube.com/watch?v=ff4fgQxPaO0)
const getData = (id) => {
    try {
        return JSON.parse(document.getElementById(id).innerHTML); // eslint-disable-line
    } catch (err) {
        console.log('error', id, err); // eslint-disable-line
        return {};
    }
};

const customFetch = ({onProgress, onFinish, onTimeout}) => (url, opts = {}) => {

    return new Promise((resolve, reject) => {

        const xhr = new XMLHttpRequest();

        xhr.open(opts.method || 'get', url)

        for (let k in opts.headers || {}) {
            xhr.setRequestHeader(k, opts.headers[k]);
        }

        xhr.onload = e => {
            return resolve({
                headers: {
                    get: (name) => {
                        return xhr.getResponseHeader(name);
                    },
                },
                ok: true,
                text: () => Promise.resolve(e.target.responseText),
                json: () => Promise.resolve(JSON.parse(e.target.responseText)),
            })
        }

        xhr.onerror = reject

        if (xhr.upload) {
            xhr.upload.onprogress = (event) => {
                if (onProgress) {
                    onProgress(event.loaded / event.total);
                }
            };

            xhr.upload.onloadend = (event) => {
                if (onFinish) {
                    onFinish(event);
                }
            };


            xhr.upload.ontimeout = (event) => {
                if (onTimeout) {
                    onTimeout(event);
                }
            };
        }
         
        xhr.send(opts.body);

    });

}


const graphqlClient = async (query, variables, options = {}) => {
    const client = new GraphQLClient('/api/scheduler/graphql', { fetch: customFetch(options) });
    return client.request(query, variables);
};

const appInfo = getData('app-state');

render((
    <ClientContext.Provider value={{ fetch: customFetch, client: graphqlClient }}>
        <AppContextProvider defaultUser={appInfo.user}>
            <App appInfo={appInfo} />
        </AppContextProvider>
    </ClientContext.Provider>
), document.body);


import { keyframes } from '@emotion/react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';

import { sizes } from '../../style/style-variables';

const keyframesShow = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const sizesBySize = {
    big: sizes.sidebarWidth,
    small: sizes.menuWidth,
};

const StyledMainContent = styled.div`
    opacity: 0;
    animation: ${keyframesShow} .4s ease-out forwards;
    padding: 6rem 2rem 2rem;
    flex: 1;
    margin-left: ${props => sizesBySize[props.size] || sizes[props.size]};
`;

const MainContent = ({ size = 'small', children }) => {
    return (
        <StyledMainContent size={ size }>
            { children }
        </StyledMainContent>
    );
};

export default MainContent;



import styled from '@emotion/styled';
import { gridTemplateColumns } from './index';
import Checkbox from '../../components/checkbox/checkbox';
import getReadableFileSizeString from '../../helpers/get-file-size';
import { colors } from '../../style/style-variables';
import FileListItemPopupMenu from './file-list-item-popup-menu';
import ThumbnailMissing from '../../components/thumbnail/thumbnail-missing';

const StyledItem = styled.div`
    background-color: ${colors['darkPurpleGray']};
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    border-radius: 5px;
    display: inline-grid;
    grid-template-columns: ${props => props.gridTemplateColumns};
    width: 100%;
    box-sizing: border-box;
    align-items: center;

    span {
        font-family: 'Roboto';
        font-weight: 300;
        font-size: 14px;
        color: ${colors['superLightGray']};
    }
`;

const StyledThumbnail = styled.img`
    border-radius: 3px;
    height: 45px;
    min-width: 140px;
    object-fit: cover;
    margin-right: 1rem;
`;

const ThumbnailMissingContainer = styled.div`
    border-radius: 3px;
    height: 45px;
    width: 140px;
    margin-right: 1rem;
    background-color: ${colors['greenishDarkGray']};
`;

const FileListItem = ({ asset, reload, selectedAssets, toggleAssetSelection }) => {

    const { thumbnail, filename, size, name, createdAt, guid } = asset;

    // const checkboxProps = {
    //     onChange: () => toggleAssetSelection(guid),
    //     checked: selectedAssets.includes(guid),
    // };

    return (
        <StyledItem gridTemplateColumns={gridTemplateColumns}>
            {/* <Checkbox { ...checkboxProps } /> */}
            { thumbnail ? (
                <StyledThumbnail src={`/thumbnail/${thumbnail}`} />
            ) : (
                <ThumbnailMissingContainer>
                    <ThumbnailMissing />
                </ThumbnailMissingContainer>
            )}
            <span>
                { name }
            </span>
            <span>
                { filename }
            </span>
            <span>
                Touch
            </span>
            <span>
                5760x1200
            </span>
            <span>
                { getReadableFileSizeString(size) }
            </span>
            <span>
                { new Date(createdAt).toLocaleString('sv-SE').slice(0, -3) }
            </span>
            <FileListItemPopupMenu asset={ asset } reload={ reload } />
        </StyledItem>
    );
};

export default FileListItem;

import { Fragment } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { route } from 'preact-router';
import isPast from 'date-fns/isPast';
import endOfDay from 'date-fns/endOfDay';

import { useMutation, useQuery } from '../../graphql-context/graphql-context';
import useFetch from '../../hooks/use-fetch';
import ButtonComponent from '../../components/button';
import { FlexRowSpaceBetween } from '../../components/styled-components/layout';
import { PageHeading, Text } from '../../components/styled-components/typography';
import ManageScreeningModal from './manage-screening-modal';
import Calendar from '../../components/calendar/calendar';
import { Spinner } from '../../components/spinner/spinner';
import Sidebar from '../../components/sidebar/sidebar';
import { MultiItemPicker } from '../../components/form';
import IconButton from '../../components/icon-button';
import Dot from '../../components/dot/dot';

import { MUTATION_EDIT_SCREENING, QUERY_GET_LOCATION, QUERY_GET_PLAYLISTS_AND_ASSETS } from './gql';
import { useAppContext } from '../../app-context/app-context';
import MainContent from '../../components/main-content/main-content';
import {
  StyledTitleTop,
  StyledTop,
  StyledGuid,
  StyledSpinner,
  StyledInfoContainer,
} from './location.style';
import { colors } from '../../style/style-variables';
import ToolTip from '../../components/tooltip/ToolTip';

const Location = (props) => {
  const { guid } = props;
  const { setMenuVisible } = useAppContext();

  const [modalVisible, setModalVisible] = useState(false);
  const [screeningData, setScreeningData] = useState({});
  const [modalMode, setModalMode] = useState(null);

  const { data: activeAgents } = useFetch('/get-connected-agents');
  const { loading, error, data, refetch } = useQuery(QUERY_GET_LOCATION, { guid });
  const {
    loading: loadingPlaylistAndAssets,
    error: errorPlaylistAndAssets,
    data: dataPlaylistAndAssets,
    refetch: refreshPlaylistAndAssets,
  } = useQuery(QUERY_GET_PLAYLISTS_AND_ASSETS, { guid });

  const [mutate] = useMutation(MUTATION_EDIT_SCREENING);

  const reload = () => {
    refetch();
    refreshPlaylistAndAssets();
  };

  useEffect(() => {
    setMenuVisible(false);
    reload();
  }, []);

  if (!data || !dataPlaylistAndAssets) {
    return (
      <StyledSpinner>
        <Spinner />
      </StyledSpinner>
    );
  }

  if (error) {
    console.log('error', error);
    return <div>Error</div>;
  }

  const buttonProps = {
    text: 'Add content',
    icon: 'add',
    onClick: () => {
      setModalVisible(true);
      setModalMode('create');
    },
    disabled: loading,
  };

  const modalProps = {
    visible: modalVisible,
    locationGuid: guid,
    closeModal: () => setModalVisible(false),
    onClose: () => reload(),
    mode: modalMode,
    screeningData,
  };

  const showCreateScreening = (event) => {
    if (!isPast(endOfDay(event.start))) {
      const date = new Date(event.start);
      const UTCAdjusted = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
      setScreeningData({
        start: UTCAdjusted,
        end: UTCAdjusted,
      });
      setModalVisible(true);
      setModalMode('create');
    }
  };

  const showEditScreening = (event) => {
    const editGuid = event.id;

    const screening = { ...data.getLocation.screenings.find(({ guid }) => guid === editGuid) };
    if (screening.type === 'asset') {
      screening.assetGuid = screening.asset.guid;
    } else {
      screening.playlistGuid = screening.playlist.guid;
    }
    delete screening.playlist;
    delete screening.asset;

    screening.start = new Date(screening.start);
    screening.end = new Date(screening.end);

    setScreeningData(screening);
    setModalMode('edit');
    setModalVisible(true);
  };

  const events = data.getLocation.screenings.map((screening) => ({
    id: screening.guid,
    isDraggable: true,
    type: screening.type,
    isResizable: screening.type == 'asset',
    title: screening[screening.type]?.name || '',
    start: new Date(screening.start),
    end: new Date(screening.end),
  }));

  const goBack = () => route('/');

  const editScreening = (event) => {
    const screening = data.getLocation.screenings.find(({ guid }) => guid === event.id);
    if (!!screening) {
      const updatedScreening = {
        input: {
          guid: screening.guid,
          type: screening.type,
          start: event.start,
          end: event.end,
        },
      };
      if (screening.type === 'asset') {
        updatedScreening.input.assetGuid = screening.asset.guid;
      }
      if (screening.type === 'playlist') {
        updatedScreening.input.playlistGuid = screening.playlist.guid;
      }
      mutate(updatedScreening);
    }
  };

  const calendarProps = {
    events,
    onSelectSlot: showCreateScreening,
    onSelectEvent: showEditScreening,
    onEventDrop: (event) => editScreening(event),
    onEventResize: (event) => editScreening(event),
  };

  const Wrapper = loading ? Spinner : Fragment;

  const showAddPlaylist = (playlistGuid) => {
    const date = new Date();
    const UTCAdjusted = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    setScreeningData({
      start: UTCAdjusted,
      end: UTCAdjusted,
      type: 'playlist',
      playlistGuid,
    });
    setModalVisible(true);
    setModalMode('create');
  };

  const showAddAsset = (assetGuid) => {
    const date = new Date();
    const UTCAdjusted = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    setScreeningData({
      start: UTCAdjusted,
      end: UTCAdjusted,
      type: 'asset',
      assetGuid,
    });
    setModalVisible(true);
    setModalMode('create');
  };

  const active = activeAgents?.some(({ location }) => location.guid === guid);

  return (
    <>
      <Sidebar padding={false}>
        <MultiItemPicker
          assets={dataPlaylistAndAssets.getAssets}
          playlists={dataPlaylistAndAssets.getPlaylists}
          onAddAsset={showAddAsset}
          onAddPlaylist={showAddPlaylist}
        />
      </Sidebar>
      <MainContent size='big'>
        <StyledTop>
          <FlexRowSpaceBetween>
            <IconButton icon={'arrow-back'} onClick={goBack} size='large' />
            <StyledInfoContainer>
              <StyledTitleTop>
                <PageHeading marginBotton='small'>{data.getLocation.name}</PageHeading>
                <ToolTip
                  content={active ? 'Online' : 'Offline'}
                  direction='right'
                  position={'15px'}
                >
                  <div style={{ display: 'flex' }}>
                    <Dot color={active ? colors.ok : colors.error} useMargin={true} />
                  </div>
                </ToolTip>
              </StyledTitleTop>
              <StyledGuid>
                <Text color='superLightGray'>Installation ID: {data.getLocation.guid}</Text>
              </StyledGuid>
            </StyledInfoContainer>
          </FlexRowSpaceBetween>
          <ButtonComponent {...buttonProps} />
        </StyledTop>
        <Wrapper overlay={true}>
          <Calendar {...calendarProps} />
        </Wrapper>
      </MainContent>
      <ManageScreeningModal {...modalProps} />
    </>
  );
};

export default Location;

import { gql } from 'graphql-request';
import { useState, useEffect, useContext } from 'preact/hooks';
import styled from '@emotion/styled';

import { PageHeading } from '../../components/styled-components/typography';
import Upload from '../../components/upload/upload';
import { ClientContext } from '../../graphql-context/graphql-context';
import FileListItem from './file-list-item';
import { colors } from '../../style/style-variables';
import MainContent from '../../components/main-content/main-content';
import Alert from '../../components/alert/Alert';
import Table from '../../components/table/Table';
import FileListItemPopupMenu from './file-list-item-popup-menu';
import ThumbnailMissing from '../../components/thumbnail/thumbnail-missing';
import getReadableFileSizeString from '../../helpers/get-file-size';

const mutation = gql`
  mutation Upload($file: Upload!, $size: Float) {
    createAsset(file: $file, size: $size) {
      filename
      key
    }
  }
`;

const getAll = gql`
  query GetAll {
    getAssets {
      filename
      key
      thumbnail
      size
      name
      createdAt
      guid
      interactivity
      lastModified
      resolution
    }
  }
`;

export const gridTemplateColumns = `170px auto 150px 110px 120px 90px 150px 40px`;

const AlertContainer = styled.div`
  min-height: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledThumbnail = styled.img`
  border-radius: 3px;
  height: 45px;
  width: 140px;
  object-fit: cover;
  margin-right: 1rem;
  vertical-align: middle;
`;

const ThumbnailMissingContainer = styled.div`
  border-radius: 3px;
  height: 45px;
  width: 140px;
  margin-right: 1rem;
  background-color: ${colors['greenishDarkGray']};
  vertical-align: middle;
`;

const Files = () => {
  const graphqlContext = useContext(ClientContext);
  const [assets, setAssets] = useState({ count: 0, limit: 100, offset: 0, list: [] });
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const reload = async () => {
    setLoading(true);
    const result = await graphqlContext.client(getAll);
    setAssets({ list: result.getAssets });
    setLoading(false);
  };

  /**
   * State for selected assets
   */
  const [selectedAssets, setSelectedAssets] = useState([]);

  const toggleAssetSelection = (guid) => {
    if (selectedAssets.includes(guid)) {
      setSelectedAssets(selectedAssets.filter((assetGuid) => assetGuid !== guid));
    } else {
      setSelectedAssets([...selectedAssets, guid]);
    }
  };

  const upload = async (files) => {
    setLoading(true);
    setHasError(false);
    setIsUploadComplete(false);

    try {
      await graphqlContext.client(
        mutation,
        { file: files[0], size: files[0].size },
        {
          onProgress: (progress) => {
            // Fake the last percent since after upload we need to do some small stuff
            setProgress(Math.max(progress * 0.98));
          },
          onFinish: () => {
            setProgress(1);
            setIsUploadComplete(true);
          },
        }
      );

      reload();
    } catch (error) {
      //when gateway error happens the client times out but usually the upload to AWS always succeeds
      const errorCode = error.Code === undefined ? 'gateway-error' : error.Code;
      if (!errorCode === 'gateway-error') {
        setHasError(true);
      }
      reload();
    }
  };

  useEffect(() => {
    reload();
  }, []);

  const files = assets.list.map((asset) => {
    const listItemProps = {
      asset,
      reload,
      selectedAssets,
      toggleAssetSelection,
      key: asset.guid,
    };
    return <FileListItem {...listItemProps} />;
  });

  const columns = [
    { Header: '', accessor: 'thumbnail' },
    { Header: 'Name', accessor: 'name' },
    { Header: 'File', accessor: 'filename' },
    { Header: 'Resolution', accessor: 'resolution' },
    { Header: 'Interactivity', accessor: 'interactivity' },
    { Header: 'Size', accessor: 'size' },
    { Header: 'Modified', accessor: 'lastModified' },
    { Header: '', accessor: 'options' },
  ];

  const tableData = assets.list.map((asset) => {
    const { thumbnail, guid, name, filename, size, createdAt, resolution, interactivity, lastModified } = asset;

    return {
      guid,
      filename,
      name,
      lastModified: !!lastModified && new Date(lastModified).toLocaleString('sv-SE').slice(0, -3),
      size: getReadableFileSizeString(size),
      createdAt: new Date(createdAt).toLocaleString('sv-SE').slice(0, -3),
      thumbnail: thumbnail ? (
        <StyledThumbnail src={`/thumbnail/${thumbnail}`} />
      ) : (
        <ThumbnailMissingContainer>
          <ThumbnailMissing />
        </ThumbnailMissingContainer>
      ),
      resolution,
      interactivity,
      options: (
        <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <FileListItemPopupMenu asset={asset} reload={reload} />
        </div>
      ),
    };
  });

  return (
    <MainContent>
      <PageHeading>Files</PageHeading>
      <Upload upload={upload} progress={progress} />
      <AlertContainer>
        <Alert
          isVisible={isUploadComplete}
          message="Info: Upload complete and is being processed. It can take a couple of minutes."
          timer={10000}
        />
        <Alert isVisible={hasError} message="Error: Upload failed." theme="error" timer={10000} />
      </AlertContainer>
      <Table data={tableData} columns={columns} isLoading={loading} sortable />
    </MainContent>
  );
};

export default Files;

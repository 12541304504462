import { gql } from 'graphql-request';
import { useState } from 'preact/hooks';
import { FlexRowEnd, Space, HorizontalSpace } from '../../components/styled-components/layout';
import Modal from '../../components/modal';
import { Input } from '../../components/form';
import Button from '../../components/button';
import { createOrganization } from '../../api/organization';

const OrganizationModal = ({ visible, closeModal, onSubmit, formValues: defaultFormValues = {} }) => {
  const [formValues, setFormValues] = useState(defaultFormValues);

  const submitForm = () => {
    const variables = {
      ...formValues,
    };

    onSubmit(variables);
    closeModal();
  };

  const disabled = !formValues['name'];

  return (
    <Modal visible={visible} closeModal={closeModal} title={'Create Organization'}>
      <Input
        label="Name"
        value={formValues['name']}
        onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
      />
      <Space />
      <FlexRowEnd>
        <Button type="text" text="Cancel" onClick={closeModal} />
        <HorizontalSpace />
        <Button text="Submit" onClick={submitForm} disabled={disabled} />
      </FlexRowEnd>
    </Modal>
  );
};

export default OrganizationModal;

import { useState, useContext } from "preact/hooks";
import { ErrorMessage } from "../../../components/styled-components/typography";
import Modal from '../../../components/modal';
import { FlexRow, FlexRowEnd, FlexRowSpaceBetween, HorizontalSpace, Space } from '../../../components/styled-components/layout';
import { Input } from '../../../components/form';
import Button from '../../../components/button';
import { Select } from '../../../components/form';
import screenTypes from '../../../helpers/screen-types';
import { ClientContext } from '../../../graphql-context/graphql-context';
import { editPlaylistInfo} from './graphql';

const EditPlaylistInfoModal = ({
    visible,
    closeModal,
    playlistData,
    playlistGuid,
    refetchPlaylist
}) => {
    const graphqlContext = useContext(ClientContext);

    const [formValues, setFormValues] = useState({
        name: playlistData.name,
        screenType: playlistData.screenType,
    });

    const onSave = async () => {
        const payload = {
            guid: playlistGuid,
            name: formValues.name,
            screenType: formValues.screenType
        };
        await graphqlContext.client(editPlaylistInfo, payload);
        closeModal();
        refetchPlaylist();
    }

    const valid = formValues.name && formValues.screenType;

    return (

        <Modal
            visible={visible}
            closeModal={closeModal}
            title={'Edit info'}
        >
            <FlexRow>
                <Input
                    label="Name"
                    value={formValues['name']}
                    onChange={e=>setFormValues({ ...formValues, ...{ name: e.target.value }})}
                />
            </FlexRow>
            <Select
                label="Screen type"
                value={formValues['screenType']}
                onChange={ e => setFormValues({ ...formValues, ...{ screenType: e.target.value }})}
                options={screenTypes}
                placeholder="Select screen type"
            />
            <Space />
            <FlexRowEnd>
                <Button type="text" text="Cancel" onClick={closeModal} />
                <HorizontalSpace />
                <Button
                    text="Save"
                    onClick={onSave}
                    disabled={!valid}
                />
            </FlexRowEnd>
        </Modal>
    );

};

export default EditPlaylistInfoModal;

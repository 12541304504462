import { Component } from "preact";


export class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(_) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('error:', error, errorInfo);
  }

  render() {
    const { children, fallbackComponent } = this.props;

    if (this.state.hasError && !!fallbackComponent) {
      return fallbackComponent;
    }

    return children;
  }
}

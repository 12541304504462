export const getOrganizations = async () => {
  const response = await fetch('/get-organizations', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });

  const data = await response.json();

  return data.organizations;
};

export const getOrganization = async (uuid) => {
  const response = await fetch(`/get-organizations/${uuid}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });

  const data = await response.json();

  return data;
};

export const createOrganization = async (name) => {
  const response = await fetch('/create-organization', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      name,
    }),
  });

  const data = await response.json();

  return data;
};

export const editOrganization = async (input) => {
  const response = await fetch('/edit-organization', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      ...input,
    }),
  });

  const data = await response.json();

  return data;
};

export const deleteOrganization = async (uuid) => {
  const response = await fetch('/delete-organization', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      uuid,
    }),
  });

  const data = await response.json();

  return data;
};

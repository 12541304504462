import { useState, useEffect } from 'preact/hooks';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import Toggle from '../../../../components/toggle';
import ErrorMessage from '../../../../components/error-message/error-message';
import {
  FlexRowEnd,
  HorizontalSpace,
  FlexRowSpaceBetween,
} from '../../../../components/styled-components/layout';
import { getValidStatus } from '../validation';
import TimePicker from '../../../../components/form/TimePicker';

const EditFileModal = ({ onSave, onDelete, visible, closeModal, playlistItem, timeOptions }) => {
  const [formValues, setFormValues] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [addButtonDisabled, setAddButtonDisabled] = useState(true);
  const [interactionWithFormHappened, setInteractionWithFormHappened] = useState(false);

  const updateFormValuesOnNewAssetLoad = () => {
    setFormValues({
      ...formValues,
      ...{
        startTime: playlistItem?.start,
        stopTime: playlistItem?.end,
        sound: playlistItem?.sound,
      },
    });
  };
  useEffect(updateFormValuesOnNewAssetLoad, [playlistItem]);

  const startTimeSelectProps = {
    label: 'Time',
    value: formValues.startTime ? formValues.startTime : playlistItem?.start,
    options: timeOptions.startTimes,
    placeholder: 'Start',
    onChange: (event) => {
      setFormValues({ ...formValues, startTime: event.target.value });
      setInteractionWithFormHappened(true);
    },
  };

  const stopTimeSelectProps = {
    value: formValues.stopTime ? formValues.stopTime : playlistItem?.end,
    options: timeOptions.stopTimes,
    placeholder: 'Stop',
    onChange: (event) => {
      setFormValues({ ...formValues, stopTime: event.target.value });
      setInteractionWithFormHappened(true);
    },
  };

  const handleToggle = () => {
    setFormValues({ ...formValues, sound: !formValues.sound });
    setInteractionWithFormHappened(true);
  };

  const checkValidStatus = () => {
    const id = playlistItem
      ? playlistItem.guid
        ? playlistItem.guid
        : playlistItem.tempGuid
        ? playlistItem.tempGuid
        : null
      : null;
    const { addButtonDisabled, errorMessage } = getValidStatus(formValues, timeOptions, id);
    setAddButtonDisabled(addButtonDisabled);
    setErrorMessage(errorMessage);
  };

  useEffect(checkValidStatus, [formValues]);

  return (
    <Modal
      visible={visible}
      closeModal={closeModal}
      title={`Edit: ${playlistItem ? playlistItem.asset.name : ''}`}
    >
      <FlexRowSpaceBetween>
        <TimePicker startProps={startTimeSelectProps} stopProps={stopTimeSelectProps} />
      </FlexRowSpaceBetween>
      <Toggle
        groupLabel='Sound'
        label='Sound on'
        checked={formValues.sound}
        onClick={handleToggle}
      />
      <FlexRowEnd>
        <ErrorMessage message={interactionWithFormHappened && errorMessage} />
      </FlexRowEnd>
      <FlexRowSpaceBetween>
        <Button text='Delete' onClick={onDelete} type='delete' />
        <FlexRowEnd>
          <Button type='text' text='Cancel' onClick={closeModal} />
          <HorizontalSpace />
          <Button
            text='Save'
            onClick={() => onSave(formValues, playlistItem)}
            disabled={addButtonDisabled}
          />
        </FlexRowEnd>
      </FlexRowSpaceBetween>
    </Modal>
  );
};

export default EditFileModal;

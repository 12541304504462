import styled from '@emotion/styled';
import ButtonComponent from '../../components/button';
import ModalComponent from '../../components/modal';
import { FlexColumn, FlexRowSpaceBetween } from '../../components/styled-components/layout';
import { roles } from '../../constants';
import { colors } from '../../style/style-variables';

const ConfirmText = styled.p`
  font-family: 'Roboto';
  color: ${colors['superLightGray']};
  font-weight: 300;
  margin-bottom: 64px;
`;

const AssignRoleModal = ({ isVisible, onClose, onSubmit, newRoleId, userName }) => {
  const modalProps = {
    visible: isVisible,
    title: 'Confirm Role Assignment',
    closeModal: onClose,
  };

  const handleOnCancel = () => {
    if (onClose) onClose();
  };

  const handleOnSubmit = () => {
    if (onSubmit) onSubmit();
  };
  return (
    <ModalComponent {...modalProps}>
      <FlexColumn>
        <ConfirmText>{`Are you sure that you want to change to role "${
          roles.find((role) => role.id === newRoleId)?.name || 'null'
        }" on user "${userName}"?`}</ConfirmText>
        <FlexRowSpaceBetween>
          <ButtonComponent text={'Cancel'} onClick={handleOnCancel} />
          <ButtonComponent text={'Save'} onClick={handleOnSubmit} theme={'primary'} />
        </FlexRowSpaceBetween>
      </FlexColumn>
    </ModalComponent>
  );
};

export default AssignRoleModal;

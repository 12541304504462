import styled from '@emotion/styled';
import { useTable, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import { useMemo } from 'preact/hooks';
import { colors } from '../../style/style-variables';
import Icons from '../icons';
import { DefaultFilterForColumn } from './Filters';

const TableHeader = styled.thead``;

const Th = styled.th`
  font-weight: normal;
  font-family: 'Roboto';
  font-size: 0.8rem;
  color: ${colors.lightGray};
  padding: 1.1rem 0.5rem;
  text-align: start;
`;

const TBodyRow = styled.tr`
  background-color: ${colors.darkPurpleGray};
  color: white;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
`;

const Td = styled.td`
  font-family: 'Roboto';
  font-size: 14px;
  padding: 0.5rem;
`;

const TableComponent = styled.table`
  width: 100%;
  border-collapse: separate;
  margin-bottom: 1rem;
  position: relative;
  min-height: 104px;
  border-spacing: 0 1em;

  td:first-of-type {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
  }
  td:last-of-type {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
  }

`;

const SpinnerComponent = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  position: relative;
  top: calc(50% - ${50 / 2}px);
  left: calc(50% - ${50 / 2}px);
  z-index: 499;
`;

const Container = styled.div`
  position: absolute;
  width: 100%;
  &:after {
    content: ' ';
    z-index: 500;
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(100, 100, 100, 0.3);
    cursor: wait;
  }
`;
const FilterSection = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid ${colors.blue};
  border-radius: 5px;
  padding: 0 1rem 1rem 1rem;
`;
const FilterContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: flex-end;
  flex: 1;
`;

const ThBox = styled.div`
  display: 'flex';
  justify-content: flex-start;
  align-items: center;
`;

const Table = ({
  columns,
  data,
  isLoading = false,
  onRowClick,
  rowClassName = null,
  sortable = false,
  filterable = false,
}) => {
  const columnsMemo = useMemo(() => columns, [columns]);

  const dataMemo = useMemo(() => data, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    columns: tableColumns,
    ...rest
  } = useTable(
    {
      filterTypes: {
        multiSelect: (rows, id, filterValues) => {
          if (filterValues.length === 0) return rows;
          return rows.filter((r) => filterValues.includes(r.values[id]));
        },
      },
      columns: columnsMemo,
      data: dataMemo,
      defaultColumn: { Filter: DefaultFilterForColumn },
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  const handleOnRowClick = (row) => {
    if (!!onRowClick) {
      onRowClick(row);
    }
  };

  return (
    <>
      {filterable && (
        <FilterSection>
          <FilterContainer>
            {tableColumns.map((column) => {
              const isFilterable = filterable && column.canFilter;
              return isFilterable && column.render('Filter');
            })}
          </FilterContainer>
        </FilterSection>
      )}

      <TableComponent {...getTableProps()} enarules="none">
        <TableHeader>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.getHeaderGroupProps().key}>
              {headerGroup.headers.map((column) => {
                const isSortable = sortable && !!column.Header;

                return (
                  <Th
                    {...column.getHeaderProps(isSortable && column.getSortByToggleProps())}
                    key={column.getHeaderProps().key}
                  >
                    <ThBox>
                      {column.render('Header')}
                      {isSortable && (
                        <Icons
                          name={column.isSortedDesc ? 'chevron-down' : 'chevron-up'}
                          style={{
                            marginLeft: '0.1rem',
                            color: `${column.isSorted ? colors.superLightGray : 'transparent'}`,
                            width: '0.8rem',
                            height: '0.8rem',
                          }}
                        />
                      )}
                    </ThBox>
                  </Th>
                );
              })}
            </tr>
          ))}
        </TableHeader>
        {isLoading ? (
          <Container>
            <SpinnerComponent></SpinnerComponent>
          </Container>
        ) : (
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TBodyRow
                  className={rowClassName}
                  {...row.getRowProps()}
                  key={row.getRowProps().key}
                  onClick={() => handleOnRowClick(row)}
                >
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()} key={cell.getCellProps().key}>
                      {cell.render('Cell')}
                    </Td>
                  ))}
                </TBodyRow>
              );
            })}
          </tbody>
        )}
      </TableComponent>
    </>
  );
};

export default Table;

import { gql } from 'graphql-request';
import { useState } from 'preact/hooks';
import { FlexRow, FlexRowEnd, HorizontalSpace } from '../../components/styled-components/layout';
import Modal from '../../components/modal';
import { Input, Select } from '../../components/form';
import Button from '../../components/button';
import { useMutation } from '../../graphql-context/graphql-context';
import { Space } from '../../components/styled-components/layout';
import screenTypes from '../../helpers/screen-types';
import Checkbox from '../../components/checkbox/checkbox';
import { Label } from '../../components/styled-components/forms';
import { Text } from '../../components/styled-components/typography';

const mutation = gql`
    mutation CreateLocation(
        $name: String!
        $screenType: String!
        $interactivity: String!
        $resolution: String!
    ) {
        createLocation(
            name: $name
            screenType: $screenType
            interactivity: $interactivity
            resolution: $resolution
        ) {
            guid
            screenType
        }
    }
`;

const CreateLocationModal = ({ onClose, visible, closeModal}) => {

    const [formValues, setFormValues] = useState({
        interactivity: ['none'],
        resolution: '5760x1200',
    });

    const [createLocation, { loading, error }] = useMutation(mutation, {
        onCompleted: () => {
            closeModal();
            onClose(true);
        },
    });

    const submitForm = () => {
        const variables = {
            ...formValues,
            interactivity: formValues.interactivity.join(','),
        };
        createLocation(variables);
        setFormValues({
            interactivity: ['none'],
            resolution: '5760x1200',
        });
    };

    const disabled = !(formValues['name']);

    const noneCheckboxProps = {
        checked: formValues.interactivity?.includes('none'),
        onChange: (event) => {
            if (event.target.checked) {
                setFormValues({
                    ...formValues,
                    interactivity: ['none'],
                });
            } else {
                setFormValues({
                    ...formValues,
                    interactivity: ['touch'],
                });
            }
        },
    };

    const touchCheckboxProps = {
        checked: formValues.interactivity?.includes('touch'),
        onChange: event => {
            if (event.target.checked) {
                setFormValues({
                    ...formValues,
                    interactivity: [ ...formValues.interactivity.filter(i => i !== 'none'), 'touch' ],
                });
            } else {
                const newValues = formValues.interactivity.filter(i => i !== 'touch');
                setFormValues({
                    ...formValues,
                    interactivity: newValues.length ? newValues : ['none'],
                });
            }
        }
    };

    const motionCheckboxProps = {
        checked: formValues.interactivity?.includes('motion'),
        onChange: event => {
            if (event.target.checked) {
                setFormValues({
                    ...formValues,
                    interactivity: [ ...formValues.interactivity.filter(i => i !== 'none'), 'motion' ],
                });
            } else {
                const newValues = formValues.interactivity.filter(i => i !== 'motion');
                setFormValues({
                    ...formValues,
                    interactivity: newValues.length ? newValues : ['none'],
                });
            }
        }
    };

    const xProps = {
        label: 'Width',
        type: 'number',
        value: formValues.resolution.split('x')[0],
        onChange: event => {
            setFormValues({
                ...formValues,
                resolution: `${event.target.value}x${formValues.resolution.split('x')[1]}`,
            });
        },
    };

    const yProps = {
        label: 'Height',
        type: 'number',
        value: formValues.resolution.split('x')[1],
        onChange: event => {
            setFormValues({
                ...formValues,
                resolution: `${formValues.resolution.split('x')[0]}x${event.target.value}`,
            });
        },
    };

    return (
        <Modal
            visible={visible}
            closeModal={closeModal}
            title={'Create Installation'}
        >
            <Input
                label="Name"
                value={formValues['name']}
                onChange={ e => setFormValues({ ...formValues, ...{ name: e.target.value }})}
            />
            <Select
                label="Screen type"
                value={formValues['screenType']}
                onChange={ e => setFormValues({ ...formValues, ...{ screenType: e.target.value }})}
                options={screenTypes}
                placeholder="Select screen type"
                maxWidth="250px"
            />
            <Label>
                Interactivity
            </Label>
            <FlexRow>
                <Text>None:</Text> <Checkbox { ...noneCheckboxProps } />
                <Text>Touch:</Text> <Checkbox { ...touchCheckboxProps } />
                <Text>Motion:</Text> <Checkbox { ...motionCheckboxProps } />
            </FlexRow>
            <FlexRow>
                <Input { ...xProps } />
                <HorizontalSpace size={1} />
                <Input { ...yProps } />
            </FlexRow>
            <Space />
            <FlexRowEnd>
                <Button type="text" text="Cancel" onClick={closeModal} />
                <HorizontalSpace />
                <Button text="Create" onClick={submitForm} disabled={disabled} loading={loading} />
            </FlexRowEnd>
        </Modal>
    );

};

export default CreateLocationModal;

import styled from '@emotion/styled';
import { useEffect, useState } from 'preact/hooks';

const Wrapper = styled.div`
  margin: 10px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 10px 20px;
  box-sizing: border-box;
`;

const Title = styled.p`
  font-family: roboto;
  font-size: 16px;
`;

const Message = styled.p`
  margin: 0px;
`;

const alertVariants = {
  info: styled(Wrapper)`
    background-color: #cfe2ff;
    p {
      color: #084298;
    }
  `,
  warning: styled(Wrapper)`
    background-color: #fff3cd;
    p {
      color: #654d03;
    }
  `,
  success: styled(Wrapper)`
    background-color: #d1e7dd;
    p {
      color: #0f5132;
    }
  `,
  error: styled(Wrapper)`
    background-color: #f8d7da;
    p {
      color: #842029;
    }
  `,
};

const Alert = ({ isVisible = true, message, title, theme = 'info', timer }) => {
  const [showAlert, setShowAlert] = useState(isVisible);

  const AlertWrapper = alertVariants[theme];

  useEffect(() => {
    setShowAlert(isVisible);

    if (!!timer) {
      const timeout = setTimeout(() => {
        setShowAlert(false);
      }, timer);
      return () => clearTimeout(timeout);
    }
  }, [isVisible]);

  if (!showAlert) {
    return null;
  }

  return (
    <AlertWrapper>
      {!!title && <Title>{title}</Title>}
      <Message>{message}</Message>
    </AlertWrapper>
  );
};

export default Alert;

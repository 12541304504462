import isBefore from 'date-fns/isBefore';
import isEqual from 'date-fns/isEqual';
import { useState, useEffect } from 'preact/hooks';
import formatISO from 'date-fns/formatISO';

import { FlexRow, FlexRowEnd, HorizontalSpace, Row } from '../../components/styled-components/layout';
import Modal from '../../components/modal';
import { Input,  Switch  } from '../../components/form';
import Button from '../../components/button';
import { Select } from '../../components/form';
import { useQuery, useMutation } from '../../graphql-context/graphql-context';
import { Label } from '../../components/styled-components/forms';
import { AnimatedContainer } from './location.style';
import { timeOptions } from '../playlists/edit-playlist/time-helpers';
import { MUTATION_CREATE_SCREENING, MUTATION_DELETE_SCREENING, MUTATION_EDIT_SCREENING, QUERY_GET_PLAYLISTS_AND_ASSETS } from './gql';
import Popover from '../../components/popover/popover';
import TimePicker from '../../components/form/TimePicker';

const switchOptions = [
    {
        label: 'Playlist',
        value: 'playlist',
    },
    {
        label: 'Asset',
        value: 'asset',
    },
];

const ManageScreeningModal = (props) => {

    const { screeningData, onClose, visible, closeModal, locationGuid, mode } = props;

    const MUTATION = mode === 'create' ? MUTATION_CREATE_SCREENING : MUTATION_EDIT_SCREENING;

    const defaultDate = new Date();

    const defaults = {
        start: screeningData.start || defaultDate,
        end: screeningData.start || defaultDate,
        type: 'playlist',
        ...screeningData,
    };

    const [formValues, setFormValues] = useState(defaults);
    const [popoverVisible, setPopoverVisible] = useState(false);

    // reset on open/close
    useEffect(() => {
        setFormValues(defaults);
    }, [visible]);

    const { loading, error, data } = useQuery(QUERY_GET_PLAYLISTS_AND_ASSETS);
    const [mutate, { loading: mutationLoading, error: mutationError }] = useMutation(MUTATION, {
        onCompleted: () => {
            closeModal();
            onClose(true);
        },
    });
    const [deleteScreening, { loading: deleteLoading, error: deleteError }] = useMutation(MUTATION_DELETE_SCREENING, {
        onCompleted: () => {
            closeModal();
            onClose(true);
        },
    });

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return console.error(error) || <div>Error!</div>;
    }

    const variables = {
        input: {
            ...formValues,
            locationGuid,
        },
    };

    /**
     * Submit create or edit operation
     */
    const submitForm = () => {
        delete variables.input.playlist;
        if (mode === 'create') {
            delete variables.input.guid;
        } else {
            delete variables.input.locationGuid;
        }
        variables.input.start = formatISO(formValues.start);
        variables.input.end = formatISO(formValues.end);
        mutate(variables);
    };

    const endIsBeforeStart = isBefore(formValues.end, formValues.start);
    const endIsStart = isEqual(formValues.end, formValues.start);

    const disabled =
        !formValues['start']
        || !formValues['end']
        || endIsBeforeStart
        || (endIsStart && formValues.type === 'asset')
        || !formValues[`${formValues.type}Guid`]
        || deleteLoading;

    const playlistSelectProps = {
        label: 'Playlist',
        value: formValues.playlistGuid || screeningData.playlistGuid || '',
        options: data.getPlaylists.map(({ name, guid }) => {
            return {
                name,
                key: guid,
            };
        }),
        onChange: (event) => {
            const chosenPlaylist = data.getPlaylists.find(({ guid }) => guid === event.target.value);
            const [startHours, startMinutes] = chosenPlaylist.items[0].start.split(':');

            const startDate = new Date(formValues.start);
            startDate.setUTCHours(startHours, startMinutes);

            const [endHours, endMinutes] = chosenPlaylist.items.at(-1).end.split(':');
            const endDate = new Date(formValues.end);
            endDate.setUTCHours(endHours, endMinutes);

            setFormValues({ ...formValues, playlistGuid: chosenPlaylist.guid, start: startDate, end: endDate });
          },
        placeholder: 'Select playlist',
    };

    const assetSelectProps = {
        label: 'Asset',
        value: formValues.assetGuid || screeningData.assetGuid || '',
        options: data.getAssets.map(({ name, guid }) => {
            return {
                name,
                key: guid,
            };
        }),
        onChange: (event) => {
            setFormValues({ ...formValues, assetGuid: event.target.value });
        },
        placeholder: 'Select asset',
    };

    const playlistCheckboxProps = {
        label: 'Playlist',
        checked: formValues.type === 'playlist',
        onChange: () => setFormValues({ ...formValues, type: 'playlist' }),
    };

    const assetCheckboxProps = {
        label: 'Asset',
        checked: formValues.type === 'asset',
        onChange: () => setFormValues({ ...formValues, type: 'asset' }),
    };

    /**
     * Props for start/end time
     * library didn't do it for me with UTC so did it manually
     */
    const startTimeSelectProps = {
        label: 'Start time',
        value: `${String(new Date(formValues.start).getUTCHours()).padStart(2, '0')}:${String(new Date(formValues.start).getUTCMinutes()).padStart(2, '0')}`,
        options: timeOptions,
        onChange: (event) => {
            const [hours, minutes] = event.target.value.split(':');
            const startDate = new Date(formValues.start);
            startDate.setUTCHours(hours, minutes);
            setFormValues({ ...formValues, start: startDate });
        },
    };

    const stopTimeSelectProps = {
        label: 'End time',
        value: `${String(new Date(formValues.end).getUTCHours()).padStart(2, '0')}:${String(new Date(formValues.end).getUTCMinutes()).padStart(2, '0')}`,
        options: timeOptions,
        onChange: event => {
            const [hours, minutes] = event.target.value.split(':')
            const endDate = new Date(formValues.end);
            endDate.setUTCHours(hours, minutes);
            setFormValues({ ...formValues, end: endDate });
        },
    };

    /**
     * Optional time controls shown if editing/creating asset screening
     */
    const timeControls = (
        <AnimatedContainer height={ formValues.type === 'asset' ? '100px' : '0' }>
            <FlexRow justifyContent="space-between">
                <TimePicker startProps={startTimeSelectProps} stopProps={stopTimeSelectProps} />
            </FlexRow>
        </AnimatedContainer>
    );

    const headingVerb = mode === 'create' ? 'Add' : 'Edit';

    const deleteButtonProps = {
        type: 'delete',
        onClick: () => {
            setPopoverVisible(true);
        },
        loading: deleteLoading,
        disabled: mutationLoading,
    };

    const deletePopoverProps = {
        visible: popoverVisible,
        onClose: () => setPopoverVisible(false),
        content: (
            <Row>
                Delete?
                <HorizontalSpace />
                <Button onClick={() => deleteScreening({ guid: screeningData.guid })} type="small">Yes</Button>
            </Row>
        ),
        position: 'top',
        backgroundColor: 'darkGray',
    };

    const errorMsg = deleteError?.response?.errors[0]?.message || mutationError?.response?.errors[0]?.message;

    const deleteButton = (
        <Popover { ...deletePopoverProps }>
            <Button { ...deleteButtonProps } >Delete</Button>
        </Popover>
    );

    const switchProps = {
        value: formValues.type,
        onChange: (type) => setFormValues({ ...formValues, type }),
        options: switchOptions,
    };

    return (
        <Modal
            visible={visible}
            closeModal={closeModal}
            title={`${headingVerb} content`}
        >
            <FlexRow justifyContent="space-between">
                <Input
                    label="Start date"
                    value={`${formValues.start.getUTCFullYear()}-${String(formValues.start.getUTCMonth() + 1).padStart(2, '0')}-${String(formValues.start.getUTCDate()).padStart(2, '0')}`}
                    onBlur={ e => setFormValues({ ...formValues, start: new Date(e.target.value.slice(0, 10)) })}
                />
                <HorizontalSpace />
                <Input
                    label="End date"
                    value={`${formValues.end.getUTCFullYear()}-${String(formValues.end.getUTCMonth() + 1).padStart(2, '0')}-${String(formValues.end.getUTCDate()).padStart(2, '0')}`}
                    onBlur={ e => {
                            setFormValues({ ...formValues, end: new Date(e.target.value.slice(0, 10)) });
                        }
                    }
                />
            </FlexRow>
            <div>
                <Label>Select content type</Label>
                {/* <Checkbox { ...playlistCheckboxProps } />
                <Checkbox { ...assetCheckboxProps } /> */}
                <Switch { ...switchProps } />
            </div>
            { formValues.type === 'playlist' ? <Select { ...playlistSelectProps } /> : null }
            { formValues.type === 'asset' ? <Select { ...assetSelectProps } /> : null }
            { timeControls }
            <br />
            { errorMsg || null}
            <Row justifyContent="space-between">
                <div>
                    { mode === 'edit' ? deleteButton : null }
                </div>
                <FlexRowEnd>
                    <Button type="text" text="Cancel" onClick={closeModal} disabled={mutationLoading} />
                    <HorizontalSpace />
                    <Button text="Save" onClick={submitForm} disabled={disabled} loading={mutationLoading} />
                </FlexRowEnd>
            </Row>
        </Modal>
    );

};

export default ManageScreeningModal;

import styled from '@emotion/styled';

export const StyledList = styled.div`
    width: 100%;
    height: 100px;
    position: relative;
    overflow: hidden;
`;

export const StyledListItem = styled.div`
    width: 100%;
    height: 100px;
    position: absolute;
`;



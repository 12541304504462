import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const overlay = `
    &:after {
        content: " ";
        z-index: 500;
        display: block;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        background: rgba(100,100,100,0.3);
        cursor: wait;
    }
`;

const SpinnerComponent = styled.div`
  display: inline-block;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  position: absolute;
  top: calc(50% - ${(props) => props.size / 2}px);
  left: calc(50% - ${(props) => props.size / 2}px);
  z-index: 499;
`;

const Container = styled.div`
  position: relative;
  ${(props) => (props.overlay ? overlay : '')}
`;

export const Spinner = ({ children, size = 50, overlay, className }) => {
  const containerProps = {
    overlay,
  };

  const spinnerProps = {
    size,
  };

  return (
    <Container {...containerProps}>
      <SpinnerComponent className={className} {...spinnerProps} />
      {children}
    </Container>
  );
};

Spinner.propTypes = {
  children: PropTypes.node,
  size: PropTypes.number,
  overlay: PropTypes.bool,
  className: PropTypes.string,
};

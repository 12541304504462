import styled from '@emotion/styled';
import { colors } from '../../../../style/style-variables';

export const DayCalendarBackground = styled.div`
    background-color: ${colors['darkPurpleGray']};
    font-family: 'Roboto';
    font-weight: 300;
    color: white;
    padding: 3rem 0;
    margin-left: -32px;
    margin-right: -32px;
`;

export const DayCalendar = styled.div`
    padding-left: 6rem;
    position: relative;
`;

export const DayCalendarInner = styled.div`
    width: 100%;
`;

export const DayCalendarRow = styled.div`
    background-color: #363b4a;
    min-height: 80px;
    margin-bottom: 2px;
    position: relative;
`;

export const TimeLabel = styled.div`
    color: ${colors['superLightGray']};
    position: absolute;
    left: -3rem;
    top: -11px;
`;

export const TimeNotchBright = styled.div`
    background-color: white;
    position: absolute;
    height: 3px;
    width: 6px;
    top: -4px;
    left: -6px;
`;

export const TimeNotchDull = styled.div`
    background-color: ${colors['lightGray']};
    position: absolute;
    height: 3px;
    width: 6px;
    left: -6px;
    top: 40px;
`;

export const Asset = styled.div`
    display: flex;
    position: absolute;
    background-color: ${colors['transparentLightBlueGray']};
    border-radius: 4px;
    padding: 1rem;
    margin-left: 2rem;
    height: ${ p => p.assetHeight }px;
    margin-bottom: 1px;
    top: ${ p => p.topOffset}px;
    box-sizing: border-box;
    cursor: pointer;
    left: 96px;
    right: 32px;
`;

export const AssetInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 48px;
`;

export const AssetNames = styled.p`
    color: ${colors['superLightGray']};
    font-family: 'Roboto';
    margin: 0;
`;

export const AssetTime = styled.p`
    color: ${colors['lightGray']};
    font-family: 'Roboto';
    font-size: 0.8em;
    font-weight: 400;
    margin: 0;
`;

export const ThumbnailWrapper = styled.div`
    height: ${props => props.height || '48'}px;
    width: 240px;
    overflow: hidden;
    border-radius: 4px;
    margin-right: 1rem;
    position: relative;
`;
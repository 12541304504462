import { FlexRowSpaceBetween, FlexColumn } from '../../components/styled-components/layout';
import { PlaylistItem, PlaylistName, PlayListInfo } from './styled-components';
import Thumbnails from '../../components/thumbnails/thumbnails';
import PlaylistCardPopoverMenu from './playlist-card-popup-menu';
import { Divider } from '../../components/styled-components/layout';

const PlaylistCardComponent = ({ name, items, guid, reload, screenType, interactivity }) => {

    const thumbnails = items.map(item => item.asset ? '/thumbnail/' + item.asset.thumbnail : null);
    return (
        <PlaylistItem href={`/playlist/${guid}`}>
            <a href={`/playlist/${guid}`}>
                <Thumbnails list={thumbnails} />
            </a>
            <FlexRowSpaceBetween>
                <FlexColumn>
                    <PlaylistName>
                        {name}
                    </PlaylistName>
                    <PlayListInfo>
                        {`${items.length} media file${items.length > 1 || items.length === 0 ? 's' : ''}`}
                        <Divider size="0.5rem" />
                        {screenType === 'projector' ? 'Projector' : screenType.toUpperCase()}
                        <Divider size="0.5rem" />
                        {interactivity.split(',').join(', ').replace(/^\w/, (c) => c.toUpperCase())}
                    </PlayListInfo>
                </FlexColumn>
                <PlaylistCardPopoverMenu
                    name={name}
                    guid={guid}
                    reload={reload}
                />
            </FlexRowSpaceBetween>
        </PlaylistItem>
    );

};

export default PlaylistCardComponent;

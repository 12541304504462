import styled from '@emotion/styled';
import { colors } from '../../style/style-variables';

export const StyledUpload = styled.div`
    width: 100%;
    flex: 1;
    padding: 1rem;
    border: 2px dashed rgba(147, 155, 185, 0.3);
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    color: ${colors['superLightGray']};
    cursor: pointer;

    &:hover {
        background-color: rgb(255 255 255 / 0.1);
        transition: background-color 0.7s ease;
    }
`;

export const StyledUploadContent = styled.div`
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledProgressContainer = styled.div`
    width: 100%;
    flex: 1;
    background-color: rgba(255, 255, 255, .25);
    border-radius: 5px;
    box-sizing: border-box;
    height: 32px;
    color: white;
    position: relative;
`;

export const StyledProgressBar = styled.div`
    position: absolute;
    width: 0%;
    height: 100%;
    flex: 1;
    background-color: ${colors['blue']};
    color: white;
    border-radius: 5px;
`;

export const StyledProgressPercent = styled.div`
    width: 100%;
    position: absolute;
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    color: white;
`;



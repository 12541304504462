import { useDropzone } from 'react-dropzone';
import { useEffect, useState, useCallback } from 'preact/hooks';
import { StyledUploadContent, StyledUpload, StyledProgressBar, StyledProgressPercent, StyledProgressContainer } from './upload.style';
import { Text } from '../styled-components/typography';
import { colors } from '../../style/style-variables';

const Upload = ({ upload, progress }) => {

    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        if (uploading && progress === 1) {
            setTimeout(() => {
                setUploading(false);
            }, 2000);
        }
    }, [progress]);

    const onDrop = useCallback(acceptedFiles => {
        setUploading(true);
        upload(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop})

    const uploadContent = isDragActive ?
        (<StyledUploadContent><Text color={colors.superLightGray}>Drop the files here ...</Text></StyledUploadContent>) :
        (<StyledUploadContent><Text color={colors.superLightGray}>Drop new content here or click to select files to upload.</Text></StyledUploadContent>);

    return (
        <StyledUpload {...getRootProps()}>
            <input {...getInputProps()} />
            { !uploading && uploadContent }
            {
                uploading && (
                    <StyledProgressContainer>
                        <StyledProgressPercent>{ Math.round(progress * 100) + '%' }</StyledProgressPercent>
                        <StyledProgressBar style={{width: 100 * progress + '%'}}/>
                    </StyledProgressContainer>
                )
            }
        </StyledUpload>
    );

}

export default Upload;

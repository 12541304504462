import styled from '@emotion/styled';
import { colors } from '../../../style/style-variables';

export const FileItem = styled.div`
    width: ${p => p.width === 'small' ? '200px' : '100%'};
    height: 180px;
    box-sizing: border-box;
`;

export const Thumbnail = styled.div`
    width: 100%;
    height: 100px;
    background-color: ${colors['darkPurpleGray']};
`;

export const Name = styled.h4`
    font-family: 'Roboto';
    font-size: 1rem;
    font-weight: 400;
    color: ${colors['superLightGray']};
    margin-top: 0.7em;
    margin-bottom: 0.3em;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const FileInfo = styled.p`
    font-family: 'Roboto';
    font-size: 0.8rem;
    font-weight: 300;
    color: ${colors['lightGray']};
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Container = styled.div`
    max-width: 260px
`;

export const StyledContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.columns || 1}, 1fr);
    gap: 24px;
`;



import { gql } from 'graphql-request';
import { useMutation } from '../../graphql-context/graphql-context';
import ModalComponent from '../../components/modal';
import ButtonComponent from '../../components/button';
import { FlexRow, Row } from '../../components/styled-components/layout';
import { FlexColumn, FlexRowSpaceBetween, Space } from '../../components/styled-components/layout';
import { Text } from '../../components/styled-components/typography';

const mutation = gql`
    mutation deleteAsset($guid: String!) {
        deleteAsset( guid: $guid )
    }
`;

const DeleteFileModal = (props) => {

    const { guid, closeModal, reload, name } = props;

    const [deleteAsset, { loading, error }] = useMutation(mutation, {
        variables: {
            guid,
        },
        onCompleted: () => {
            closeModal();
            reload();
        },
    });

    const deleteModalProps = {
        ...props,
    };

    return (
        <ModalComponent { ...deleteModalProps }>
            <Text>
                Do you want to delete content "{name}"?
            </Text>
            <Space />
            <Row justifyContent="center">
                {error?.response?.errors[0]?.message}
            </Row>
            <FlexRowSpaceBetween>
                <ButtonComponent onClick={closeModal}>
                    Cancel
                </ButtonComponent>
                <ButtonComponent
                    loading={loading}
                    onClick={() => deleteAsset()}
                    theme={'delete'}
                >
                    Delete
                </ButtonComponent>
            </FlexRowSpaceBetween>
        </ModalComponent>
    );
};

export default DeleteFileModal;

import styled from '@emotion/styled';
import { colors } from '../../style/style-variables';

export const StyledCalendar = styled.div`
  .rbc-day-bg {
    background-color: #363b4a;
  }

  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid #263039;
    /* #2B383E; */
  }

  .rbc-month-row + .rbc-month-row {
    border-top: 1px solid #263039;
  }

  .rbc-date-cell {
    margin-bottom: 0.5rem;
  }

  .rbc-date-cell > a {
    width: 30px;
    font-family: roboto;
    padding: 5px 5px 5px 8px;
    display: block;
    color: #b6babf;
    text-align: left;
    margin-top: 3px;
    margin-left: 3px;
  }

  .rbc-now > a {
    border-radius: 100rem;
    background-color: #4b5ea0;
    padding: 5px;
    display: block;
    text-align: center;
    margin-top: 3px;
    margin-left: 3px;
    color: white;
  }

  .rbc-off-range > a {
    color: #ffffff33;
  }

  .rbc-month-view {
    border: none;
    border-radius: 8px;
    overflow: hidden;
  }

  .rbc-time-view {
    border: none;
    * {
      border: none;
    }
    .rbc-header {
      background-color: #363b4a;
      color: #b6babf;
    }

    .rbc-today {
      a {
        span {
          border-radius: 100rem;
          background-color: #4b5ea0;
          display: block;
          color: white;
        }
      }
    }

    .rbc-time-content {
      background-color: #363b4a;
      color: #b6babf;

      .rbc-today {
        background-color: transparent;
      }
      .rbc-current-time-indicator {
        position: absolute;
        z-index: 3;
        left: 0;
        right: 0;
        height: 2px;
        background-color: ${colors['dullRed']};
        pointer-events: none;
      }
    }

    .rbc-row {
      border-top: 1px solid #263039;
    }

    .rbc-row-segment {
      border-right: 1px solid #263039;
    }
    .rbc-time-slot {
      border-right: 1px solid #263039;
      border-bottom: 1px solid #263039;
    }
  }

  .rbc-month-header {
    display: none;
  }

  .rbc-toolbar-label {
    font-family: roboto;
    color: #b6babf;
    font-size: 24px;
    margin-right: 10%;
  }

  .rbc-toolbar button:active {
    background-color: #4b5ea0;
  }

  button.rbc-active {
    background-color: #4b5ea0 !important;
    border: none;
    &:hover {
      border: none;
    }
  }
  .rbc-btn-group button {
    background-color: #2d3541;
    color: #b6babf !important;
    border: none;
  }

  .rbc-event {
    font-family: roboto;
    background-color: #6b7a99;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #b6babf;
    opacity: 0.5;
  }

  .rbc-overlay {
    background-color: #363b4a;
    border-radius: 6px;
    padding: 1rem;
  }

  .rbc-overlay-header {
    border: none;
  }

  //Had to manually move the react-big-calendar styling to make drag and drop work.

  $out-of-range-color: lighten(#333, 40%) !default;
  $out-of-range-bg-color: lighten(#333, 70%) !default;

  $calendar-border: #ddd !default;
  $cell-border: #ddd !default;

  $border-color: #ccc !default;

  // Each calendar segment is 1/7th.
  $segment-width: 0.14286% !default;

  $time-selection-color: white !default;
  $time-selection-bg-color: rgba(0, 0, 0, 0.5) !default;
  $date-selection-bg-color: rgba(0, 0, 0, 0.1) !default;

  $event-bg: #3174ad !default;
  $event-border: darken(#3174ad, 10%) !default;
  $event-outline: #3b99fc !default;
  $event-color: #fff !default;
  $event-border-radius: 5px !default;
  $event-padding: 2px 5px !default;
  $event-zindex: 4 !default;

  $btn-color: #373a3c !default;
  $btn-bg: #fff !default;
  $btn-border: #ccc !default;

  $current-time-color: #74ad31 !default;

  $rbc-css-prefix: rbc-i !default;

  $today-highlight-bg: #eaf6ff !default;

  .rbc-addons-dnd {
    .rbc-addons-dnd-row-body {
      position: relative;
    }
    .rbc-addons-dnd-drag-row {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    .rbc-addons-dnd-over {
      background-color: rgba(
        red($date-selection-bg-color),
        green($date-selection-bg-color),
        blue($date-selection-bg-color),
        0.3
      );
    }

    .rbc-event {
      transition: opacity 150ms;

      &:hover {
        .rbc-addons-dnd-resize-ns-icon {
          display: block;
        }
        .rbc-addons-dnd-resize-ew-icon {
          display: block;
        }
      }
    }

    .rbc-addons-dnd-dragged-event {
      opacity: 0;
    }

    &.rbc-addons-dnd-is-dragging .rbc-event:not(.rbc-addons-dnd-dragged-event):not(.rbc-addons-dnd-drag-preview) {
      opacity: 0.5;
    }

    .rbc-addons-dnd-resizable {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .rbc-addons-dnd-resize-ns-anchor {
      width: 100%;
      text-align: center;
      position: absolute;
      &:first-of-type {
        top: 0;
      }
      &:last-child {
        bottom: 0;
      }

      .rbc-addons-dnd-resize-ns-icon {
        display: none;
        border-top: 3px double;
        margin: 0 auto;
        width: 10px;
        cursor: ns-resize;
      }
    }

    .rbc-addons-dnd-resize-ew-anchor {
      position: absolute;
      top: 4px;
      bottom: 0;
      &:first-of-type {
        left: 0;
      }
      &:last-child {
        right: 0;
      }

      .rbc-addons-dnd-resize-ew-icon {
        display: none;
        border-left: 3px double;
        margin-top: auto;
        margin-bottom: auto;
        height: 10px;
        cursor: ew-resize;
      }
    }
  }
`;

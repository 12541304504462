import styled from '@emotion/styled';
import { colors } from '../../style/style-variables';
import { FormGroup, Label } from '../styled-components/forms';
import { FlexRow, HorizontalSpace } from '../styled-components/layout';

const Toggle = styled.div`
    width: 40px;
    height: 20px;
    border-radius: 10px;
    display: inline-block;
    cursor: pointer;
    margin-top: 12px;
`;

const ToggleInput = styled.input`
    display: none;
`;

const ToggleFill = styled.div`
    position: relative;
    width: 34px;
    height: 12px;
    border-radius: 10px;
    transition: background 0.2s;
    background-color: ${p => colors[ p.checked ? 'darkPurpleGray' : 'lightGray']};

    &:after {
        content: "";
        position: absolute;
        top: -4px;
        left: 0;
        width: 20px;
        height: 20px;
        background-color: ${colors['blue']};
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        transition: transform 0.2s;
        ${ p => p.checked ? 'transform: translateX(15px);' : '' }
    }
`;

const ToggleLabel = styled.p`
    font-size: 1rem;
    color: ${colors['superLightGray']};
    font-family: 'Roboto';
    font-weight: 300;
`;

const ToggleComponent = ({ groupLabel,label,checked,onClick }) => (
    <FormGroup>
        { groupLabel ? <Label>{ groupLabel }</Label> : null}
        <FlexRow>
            <Toggle onClick={onClick} checked={checked}>
                <ToggleInput type="checkbox" />
                <ToggleFill checked={checked} />
            </Toggle>
            <HorizontalSpace />
            <ToggleLabel>{ label }</ToggleLabel>
        </FlexRow>
    </FormGroup>
);

export default ToggleComponent;
import axios from 'axios';

export const getUsers = async () => {
  const response = await fetch('/get-users', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });

  const data = await response.json();

  return data.users;
};

export const adminCreateUser = async ({ firstName, lastName, email, roleId, organizationUuid }) =>
  axios.post('/admin-create-user', {
    firstName,
    lastName,
    email,
    roleId,
    organizationUuid,
  });

export const deleteUser = async ({ email, full }) => {
  const response = await fetch('/delete-user', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email,
      full,
    }),
  });

  const data = await response.json();

  return data;
};

export const setUserOrganization = async ({ userId, organizationId }) => {
  const response = await fetch('/set-user-organization', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      userId,
      organizationId,
    }),
  });

  const data = await response.json();

  return data;
};

export const setUserRole = async ({ userId, roleId }) => {
  const response = await fetch('/set-user-role', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      userId,
      roleId,
    }),
  });

  const data = await response.json();

  return data;
};

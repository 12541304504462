import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useEffect } from 'preact/hooks';
import { colors } from '../../style/style-variables';

const Wrapper = styled.div`
    position: relative;
`;

const positions = {
    bottomLeft: css`
        top: 110%;
        right: 50%;
    `,
    top: css`
        bottom: 110%;
    `,
}

const Container = styled.div`
    position: absolute;
    ${props => positions[props.position]}
    background-color: ${props => colors[props.backgroundColor]};
    padding: 0.5rem 1rem;
    z-index: 100;
    border-radius: 4px;
    min-width: 100px;
    width: max-content;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 30%);
`;

const stopPropagationAndPreventDefault = e => {
    e.preventDefault();
    e.stopPropagation();
}

const Popover = (props) => {

    const {
        visible,
        children,
        onClose,
        content,
        position = 'bottomLeft',
        backgroundColor = 'darkPurpleGray',
    } = props;

    useEffect(() => {

        const listener = (event) => {
            if (typeof onClose === 'function') {
                onClose(event);
            }
        }

        if (visible) {
            window.addEventListener('click', listener);
        }

        return () => {
            window.removeEventListener('click', listener);
        }

    }, [visible]);

    if (!visible) return (
        <Wrapper>
            {children}
        </Wrapper>
    );

    const containerProps = {
        position,
        backgroundColor,
        onClick: stopPropagationAndPreventDefault,
    };

    return (
        <Wrapper>
            <Container { ...containerProps }>{content}</Container>
            {children}
        </Wrapper>
    );
};

export default Popover;

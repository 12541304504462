import styled from '@emotion/styled';
import { colors } from '../../style/style-variables';

export const PlaylistItem = styled.div`
    background-color: ${colors['darkPurpleGray']};
    width: 340px;
    height: 180px;
    border-radius: 10px;
    margin-right: 2rem;
    margin-bottom: 1.5rem;
    box-sizing: border-box;
    padding: 0.7rem;
`;

export const Thumbnail = styled.div`
    width: 100%;
    height: 100px;
    background-color: ${colors['darkPurpleGray']};
    border-radius: 2px;
`;

export const PlaylistName = styled.h3`
    font-family: 'Roboto';
    font-size: 1.2rem;
    font-weight: 400;
    color: ${colors['superLightGray']};
    margin-top: 0.7em;
    margin-bottom: 0.3em;
`;

export const PlayListInfo = styled.p`
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 300;
    color: ${colors['lightGray']};
    margin: 0;
    display: flex;
`;

export const ListContainer = styled.div`
    padding: 3rem 2rem;
    height: 100vh;
    overflow: auto;
`;
import { useEffect, useState } from 'preact/hooks';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import Toggle from '../../../../components/toggle';
import {
  Space,
  FlexRowEnd,
  HorizontalSpace,
  FlexRowSpaceBetween,
} from '../../../../components/styled-components/layout';
import ErrorMessage from '../../../../components/error-message/error-message';
import { getValidStatus } from '../validation';
import TimePicker from '../../../../components/form/TimePicker';

const AddFileModal = ({ onAdd, visible, closeModal, asset, timeOptions }) => {
  const [formValues, setFormValues] = useState({ sound: false });
  const [errorMessage, setErrorMessage] = useState(null);
  const [addButtonDisabled, setAddButtonDisabled] = useState(true);
  const [interactionWithFormHappened, setInteractionWithFormHappened] = useState(false);

  const startTimeSelectProps = {
    label: 'Time',
    value: formValues.startTime,
    options: timeOptions.startTimes,
    placeholder: 'Start',
    onChange: (event) => {
      setFormValues({ ...formValues, startTime: event.target.value });
      setInteractionWithFormHappened(true);
    },
  };

  const stopTimeSelectProps = {
    value: formValues.stopTime,
    options: timeOptions.stopTimes,
    placeholder: 'Stop',
    onChange: (event) => {
      setFormValues({ ...formValues, stopTime: event.target.value });
      setInteractionWithFormHappened(true);
    },
  };

  const addFile = () => {
    onAdd(formValues, asset);
    setFormValues({ sound: false });
  };

  const checkValidStatus = () => {
    const { addButtonDisabled, errorMessage } = getValidStatus(formValues, timeOptions);
    setAddButtonDisabled(addButtonDisabled);
    setErrorMessage(errorMessage);
  };

  const handleToggle = () => {
    setFormValues({ ...formValues, sound: !formValues.sound });
    setInteractionWithFormHappened(true);
  };

  useEffect(checkValidStatus, [formValues]);

  return (
    <Modal visible={visible} closeModal={closeModal} title={`Add: ${asset ? asset.name : ''}`}>
      <FlexRowSpaceBetween>
        <TimePicker startProps={startTimeSelectProps} stopProps={stopTimeSelectProps} />
      </FlexRowSpaceBetween>
      <Toggle
        groupLabel='Sound'
        label='Sound on'
        checked={formValues.sound}
        onClick={handleToggle}
      />
      <Space />
      <FlexRowEnd>
        <ErrorMessage message={interactionWithFormHappened && errorMessage} />
      </FlexRowEnd>
      <FlexRowEnd>
        <Button type='text' text='Cancel' onClick={closeModal} />
        <HorizontalSpace />
        <Button text='Add' onClick={addFile} disabled={addButtonDisabled} />
      </FlexRowEnd>
    </Modal>
  );
};

export default AddFileModal;

import styled from '@emotion/styled';
import { useForm, Controller } from 'react-hook-form';
import ButtonComponent from '../../components/button';
import { Input, Select } from '../../components/form';
import ModalComponent from '../../components/modal';
import { FlexColumn, FlexRowSpaceBetween, Space } from '../../components/styled-components/layout';
import { useGetOrganizations } from '../../hooks/organization.hooks';
import { useAdminCreateUser } from '../../hooks/user.hooks';
import PropTypes from 'prop-types';
import { useGetRoles } from '../../hooks/role.hooks';
import { isActionAllowed } from '../../helpers/roles';
import { PERMISSIONS } from '../../constants';
import { useContext, useState } from 'preact/hooks';
import { AppContext } from '../../app-context/app-context';
import { Text } from '../../components/styled-components/typography';
import { getErrorMessage } from '../../helpers/get-error-message';

const FormBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 64px;
`;

export const UserModal = ({ isVisible, onClose, onSubmit }) => {
  const [error, setError] = useState(null);
  const { user } = useContext(AppContext);

  const {
    control,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();

  const isAllowedToSetOrganization = isActionAllowed(
    PERMISSIONS.SET_USER_ORGANIZATION,
    user?.role_id,
  );

  const { mutate } = useAdminCreateUser(
    () => {
      if (onSubmit) {
        onSubmit();
      }
      reset();
      clearErrors();
    },
    (error) => {
      setError(getErrorMessage(error));
    },
  );
  const { data: organizations = [] } = useGetOrganizations(isAllowedToSetOrganization);
  const { data: roles = [] } = useGetRoles();

  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
    reset();
    clearErrors();
    setError(null);
  };

  const formSubmit = async ({ firstName, lastName, email, role, organization }) => {
    mutate({
      firstName,
      lastName,
      email,
      roleId: role,
      organizationUuid: isAllowedToSetOrganization ? organization : user?.organizationUuid,
    });
  };

  return (
    <ModalComponent visible={isVisible} title='Create User' closeModal={handleOnClose}>
      {error && (
        <>
          <Space size={1} />
          <Text color='error'>{error}</Text>
          <Space size={1} />
        </>
      )}

      <form onSubmit={handleSubmit(formSubmit)}>
        <FlexColumn>
          <FormBox>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input label='First Name' onChange={onChange} onBlur={onBlur} value={value}></Input>
              )}
              name='firstName'
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input label='Last Name' onChange={onChange} onBlur={onBlur} value={value}></Input>
              )}
              name='lastName'
            />
            <Controller
              control={control}
              rules={{ required: 'Email Address is required' }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  errorMsg={errors.email?.message}
                  type='email'
                  label='Email'
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                ></Input>
              )}
              name='email'
            />
            {isAllowedToSetOrganization && (
              <Controller
                control={control}
                defaultValue={null}
                rules={{ required: 'Organization is required' }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    errorMsg={errors.organization?.message}
                    options={organizations.map((organization) => ({
                      key: organization.uuid,
                      name: organization.name,
                    }))}
                    placeholder='Select organization'
                    label='Organization'
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  ></Select>
                )}
                name='organization'
              />
            )}

            <Controller
              control={control}
              rules={{ required: 'Role is required' }}
              defaultValue={null}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  errorMsg={errors.role?.message}
                  options={roles
                    .filter((role) => role.id >= user.role_id)
                    .map((role) => ({
                      key: role.id,
                      name: role.name,
                    }))}
                  placeholder='Select role'
                  label='Role'
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  isLoading
                ></Select>
              )}
              name='role'
            />
          </FormBox>
          <FlexRowSpaceBetween>
            <ButtonComponent text={'cancel'} onClick={handleOnClose} />
            <ButtonComponent text={'add'} type='submit' theme={'primary'} />
          </FlexRowSpaceBetween>
        </FlexColumn>
      </form>
    </ModalComponent>
  );
};

UserModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

import styled from '@emotion/styled';
import { colors } from '../../style/style-variables';

export const FlexRow = styled.div`
    display: flex;
    align-items: center;
`;

export const Row = styled.div`
    color: ${colors['lightGray']};
    display: flex;
    align-items: center;
    justify-content: ${props => props.justifyContent || 'inherit'};
    margin: 1rem 0;
`;

export const FlexRowSpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const FlexRowEnd = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

export const WrappingFlexRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-right: -2rem;
`;

export const Space = styled.div`
    height: ${props => props.size || '4'}rem;
`;

export const HorizontalSpace = styled.div`
    width: ${props => props.size || '2'}rem;
`;

export const Divider = styled.div`
    margin-left: ${props => props.size || '1rem'};
    margin-right: ${props => props.size || '1rem'};

    &:after {
        content: '|';
    }
`;

import { Controller, useForm } from 'react-hook-form';
import { route } from 'preact-router';
import styled from '@emotion/styled';
import { Input } from '../../components/form';
import { SectionHeading } from '../../components/styled-components/typography';
import { colors } from '../../style/style-variables';
import ButtonComponent from '../../components/button';
import { Space } from '../../components/styled-components/layout';

const Container = styled.div`
  width: 100%;
  max-width: 620px;
  margin: auto;
  padding: 6rem;
  border-radius: 9px;
  background-color: ${colors['darkPurpleGray']};
`;

const ForgotPassword = () => {
  const {
    control,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();

  const formSubmit = async ({ email }) => {
    try {
      const response = await fetch('/forgot-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: email.toLowerCase(),
        }),
      });

      const data = await response.json();

      route('/confirm-forgot-password');
      reset();
      clearErrors();
    } catch (error) {}
  };

  return (
    <Container>
      <SectionHeading>Forgot password</SectionHeading>
      <form onSubmit={handleSubmit(formSubmit)}>
        <Controller
          control={control}
          rules={{ required: 'Email address is required' }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              errorMsg={errors.email?.message}
              type="email"
              label="Email"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            ></Input>
          )}
          name="email"
        />
        <Space size={1}></Space>
        <ButtonComponent type="submit">Continue</ButtonComponent>
      </form>
    </Container>
  );
};

export default ForgotPassword;

import styled from '@emotion/styled';
import { ErrorMessage } from '../../components/styled-components/typography';

const ErrorMessageRow = styled.div`
    opacity: ${ p => p.message ? '1' : '0'};
    transition: opacity 0.5s ease;
    min-height: 51px;
`;

const ErrorMessageComponent = ({ message }) => (
    <ErrorMessageRow message={message}>
        <ErrorMessage>
            {message}
        </ErrorMessage>
    </ErrorMessageRow>
);

export default ErrorMessageComponent;
import { useContext, useEffect, useState } from 'preact/hooks';
import axios from 'axios';
import { PopupMenuItem } from '../../components/styled-components/typography';
import { AppContext } from '../../app-context/app-context';
import Popover from '../../components/popover/popover';
import IconButtonComponent from '../../components/icon-button';
import SliderToggle from '../../components/slider-toggle/slider-toggle';

const HeaderSettingsItemPopupMenu = () => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { user, setUser } = useContext(AppContext);
  const [isChecked, setIsChecked] = useState(
    user.emailNotification ? user.emailNotification : true,
  );

  useEffect(async () => {
    const { data } = await axios.post('/get-user', { uuid: user.uuid });
    setUser({
      ...user,
      emailNotification: data.user.emailNotification,
    });
  }, []);

  useEffect(() => {
    setIsChecked(user.emailNotification);
  }, [user.emailNotification]);

  const handleToggleChange = async () => {
    const payload = {
      uuid: user.uuid,
      emailNotification: !user.emailNotification,
    };
    const { data } = await axios.put('/set-email-notification', payload);
    setUser({
      ...user,
      emailNotification: data.user.emailNotification,
    });
  };

  const popoverContent = (
    <PopupMenuItem onClick={handleToggleChange}>
      <div style={{ marginBottom: '10px' }}>Email notifications</div>
      <SliderToggle isChecked={isChecked} />
    </PopupMenuItem>
  );

  const iconProps = {
    icon: 'more',
    style: 'transparent',
    onClick: (e) => {
      e.stopPropagation();
      e.preventDefault();
      setPopoverOpen(!popoverOpen);
    },
  };

  const popoverProps = {
    visible: popoverOpen,
    content: popoverContent,
    onClose: () => {
      setPopoverOpen(false);
    },
  };

  return (
    <Popover {...popoverProps}>
      <IconButtonComponent {...iconProps} />
    </Popover>
  );
};

export default HeaderSettingsItemPopupMenu;

import Thumbnail from '../../../../components/thumbnail/thumbnail';
import { colors } from '../../../../style/style-variables';
import { hours } from '../time-helpers';
import Icons from '../../../../components/icons';
import {
  DayCalendarBackground,
  DayCalendar,
  DayCalendarRow,
  DayCalendarInner,
  TimeLabel,
  TimeNotchBright,
  TimeNotchDull,
  Asset,
  AssetInfo,
  AssetNames,
  AssetTime,
  ThumbnailWrapper,
} from './styled-components';

const hourHeight = 80;
const hourMargin = 2;

const getHours = (time) => time.split(':')[0];
const getMin = (time) => time.split(':')[1];

const getAssetTopOffset = ({ start }) => {
  const hours = getHours(start);
  const mins = getMin(start);
  return hours * (hourHeight + hourMargin) + (hourHeight / 60) * mins;
};

const getAssetHeight = ({ start, end }) => {
  const span = getHours(end) - getHours(start) - getMin(start) / 60 + getMin(end) / 60;

  return span * (hourHeight + hourMargin) - 5;
};

const iconStyle = {
  fill: `${colors['superLightGray']}`,
  position: 'absolute',
  right: '3px',
  top: '3px',
  zIndex: '1',
  filter: 'drop-shadow( 2px 2px 2px rgba(0, 0, 0, 0.7))',
};

const PlaylistSchedule = ({ playlistItems, handleAssetClick }) => (
  <DayCalendarBackground>
    <DayCalendar>
      <DayCalendarInner>
        {hours.slice(0, -1).map((hour) => (
          <DayCalendarRow>
            <TimeLabel>{hour}</TimeLabel>
            <TimeNotchBright />
            <TimeNotchDull />
          </DayCalendarRow>
        ))}
        {playlistItems.map((asset) => {
          const height = getAssetHeight(asset);
          const thumbnailHeight = Math.min(200, height) - 28;
          const otherItemsSameTime = playlistItems.filter(
            (f) => String(f.start).substring(0, 2) === String(asset.start).substring(0, 2),
          );

          const myIndex = otherItemsSameTime
            .map((m) => (m.guid ? m.guid : m.tempGuid))
            .indexOf(asset.guid ? asset.guid : asset.tempGuid);

          return (
            <Asset
              style={{
                marginLeft:
                  otherItemsSameTime.length > 1
                    ? `${(85 / otherItemsSameTime.length) * myIndex}%`
                    : 'initial',
                width: `${85 / otherItemsSameTime.length}%`,
              }}
              topOffset={getAssetTopOffset(asset)}
              assetHeight={height}
              onClick={(e) => handleAssetClick(asset.guid || asset.tempGuid || null)}
            >
              <ThumbnailWrapper height={thumbnailHeight}>
                <Icons name={asset.sound ? 'sound-on' : 'sound-off'} style={iconStyle} />
                <Thumbnail image={'/thumbnail/' + asset.asset.thumbnail} height={thumbnailHeight} />
              </ThumbnailWrapper>
              <AssetInfo>
                <AssetNames>{`${asset.asset.name} - ${asset.asset.filename}`}</AssetNames>
                <AssetTime>{`${asset.start} - ${asset.end}`}</AssetTime>
              </AssetInfo>
            </Asset>
          );
        })}
      </DayCalendarInner>
    </DayCalendar>
  </DayCalendarBackground>
);

export default PlaylistSchedule;

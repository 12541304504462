import moment from 'moment';

export const hours = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
  '24:00',
];

export const timeOptions = hours.map((val) => ({
  name: val,
  key: val !== '24:00' ? val : '23:59',
  disabled: false,
}));

export const hoursVacantDictionary = hours.reduce((result, current) => {
  result[current] = {
    occupied: false,
    occupiedBy: null,
  };
  return result;
}, {});

export const convertHoursListToOccupiedHoursDictionary = (hourList, itemId) =>
  hourList.reduce((result, current) => {
    result[current] = {
      occupied: true,
      occupiedBy: itemId,
    };
    return result;
  }, {});

export const getHours = (time) => time.split(':')[0];

export const getRunningTime = (end, start) => getHours(end) - getHours(start);

export const endTimeBeforeStartTime = (end, start) => getHours(start) - getHours(end) > 0;

export const occupiedTimesBetweenStartAndStop = (end, start, timeOptions) => {
  const runningTime = getRunningTime(end, start);
  const hourIndexOfStart = hours.findIndex((item) => start === item);
  const runningHours = hours.slice(hourIndexOfStart + 1, hourIndexOfStart + runningTime);
  const collidingStartTimes =
    runningHours.filter((hour) => {
      let result = false;
      result = timeOptions.startTimes.filter((timeOption) => timeOption.name === hour)[0].disabled;
      result = timeOptions.stopTimes.filter((timeOption) => timeOption.name === hour)[0].disabled;
      return result;
    }).length > 0;
  return collidingStartTimes;
};

const isIntervalWithin = (startInterval, endInterval, startTime, endTime) => {
  const startMoment = moment(startTime, 'HH:mm');
  const endMoment = moment(endTime, 'HH:mm');
  const startIntervalMoment = moment(startInterval, 'HH:mm');
  const endIntervalMoment = moment(endInterval, 'HH:mm');

  if (startMoment.isSame(endIntervalMoment)) {
    return endMoment.isBetween(startIntervalMoment, endIntervalMoment, null, '[]');
  } else if (endMoment.isSame(startIntervalMoment)) {
    return startMoment.isBetween(startIntervalMoment, endIntervalMoment, null, '[]');
  } else {
    return (
      startMoment.isBetween(startIntervalMoment, endIntervalMoment, null, '[]') ||
      endMoment.isBetween(startIntervalMoment, endIntervalMoment, null, '[]') ||
      startIntervalMoment.isBetween(startMoment, endMoment, null, '[]') ||
      endIntervalMoment.isBetween(startMoment, endMoment, null, '[]')
    );
  }
};

export const newOccupiedTimesBetweenStartAndStop = (end, start, timeOptions, id) => {
  const { localPlayListItems } = timeOptions;

  let isValid = true;
  localPlayListItems.forEach((item) => {
    const currentId = item.guid ? item.guid : item.tempGuid;
    if (id === currentId) {
      console.log(
        `Same id for the edit on interval - ${id} - ${currentId} - (${start}-${end}) / (${item.start}-${item.end})`,
      );
    } else if (isIntervalWithin(item.start, item.end, start, end)) {
      console.log(`Interval (${start}-${end}) is within (${item.start}-${item.end})`);
      isValid = false;
    } else {
      console.log(`Interval (${start}-${end}) is NOT within (${item.start}-${item.end})`);
    }
  });
  return !isValid;
};

import { useContext, useEffect, useState } from 'preact/hooks';
import { route } from 'preact-router';
import Sidebar from '../../../components/sidebar/sidebar';
import Button from '../../../components/button';
import { PageHeading } from '../../../components/styled-components/typography';
import { FilePicker } from '../../../components/form';
import AddFileModal from './add-file-modal';
import { ClientContext, useQuery } from '../../../graphql-context/graphql-context';
import PlaylistSchedule from './playlist-schedule';
import ConfirmModal from '../../../components/confirm-modal';
import EditFileModal from './edit-file-modal';
import PlaylistPopupMenu from './playlist-popup-menu';
import EditPlaylistInfoModal from '../edit-playlist/edit-playlist-info-modal';
import IconButton from '../../../components/icon-button';
import { getPlaylist, getPlaylistItems, getAssets, editPlaylist } from './graphql';
import { colors, sizes } from '../../../style/style-variables';
import { TextSmall } from '../../../components/styled-components/typography';
import {
  FlexRowSpaceBetween,
  FlexRow,
  HorizontalSpace,
  Space,
  FlexRowEnd,
  FlexColumn,
} from '../../../components/styled-components/layout';
import {
  timeOptions,
  hours,
  hoursVacantDictionary,
  convertHoursListToOccupiedHoursDictionary,
  getHours,
} from './time-helpers';
import MainContent from '../../../components/main-content/main-content';
import { AppContext } from '../../../app-context/app-context';
import { ListContainer } from '../styled-components';

const EditPlaylist = ({ guid, name }) => {
  const graphqlContext = useContext(ClientContext);
  const { setMenuVisible } = useContext(AppContext);
  const [assets, setAssets] = useState({ count: 0, limit: 100, offset: 0, list: [] });
  const [localPlayListItems, setLocalPlaylistItems] = useState([]);
  const { data, loading, error, refetch } = useQuery(getPlaylist, { guid });
  const [savedStatus, setSavedStatus] = useState(true);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [confirmGoBackModalVisible, setConfirmGoBackModalVisible] = useState(false);
  const [confirmDeleteItemModalVisible, setConfirmDeleteItemModalVisible] = useState(false);
  const [currentFileInAddModal, setCurrentFileInAddModal] = useState(null);
  const [currentFileInEditModal, setCurrentFileInEditModal] = useState(null);
  const [occupiedStartTimes, setOccupiedStartTimes] = useState(hoursVacantDictionary);
  const [occupiedEndTimes, setOccupiedEndTimes] = useState(hoursVacantDictionary);
  const [editInfoModalVisible, setEditInfoModalVisible] = useState(false);

  useEffect(() => setMenuVisible(false), []);

  const loadAssets = async () => {
    const result = await graphqlContext.client(getAssets);
    setAssets({ list: result.getAssets });
  };
  useEffect(loadAssets, []);

  const loadPlaylistItems = async () => {
    const result = await graphqlContext.client(getPlaylistItems, { playlistGuid: guid });
    setLocalPlaylistItems(result.getPlaylistItems);
  };
  useEffect(loadPlaylistItems, []);

  const manageOccupiedHourSlots = () => {
    let newOccupiedStartTimesDictionary = hoursVacantDictionary;
    let newOccupiedEndTimesDictionary = hoursVacantDictionary;

    localPlayListItems.forEach((item) => {
      const startTimesToOccupy = [item.start];
      const endTimesToOccupy = [item.end];
      const runningTime = getHours(item.end) - getHours(item.start);

      if (runningTime > 1) {
        const indexOfFirstBetweenTime = hours.findIndex((hour) => hour === item.start) + 1;
        const betweenTimes = hours.slice(
          indexOfFirstBetweenTime,
          indexOfFirstBetweenTime + runningTime - 1,
        );

        betweenTimes.forEach((time) => {
          startTimesToOccupy.push(time);
          endTimesToOccupy.push(time);
        });
      }

      newOccupiedStartTimesDictionary = {
        ...newOccupiedStartTimesDictionary,
        ...convertHoursListToOccupiedHoursDictionary(
          startTimesToOccupy,
          item.guid || item.tempGuid,
        ),
      };

      newOccupiedEndTimesDictionary = {
        ...newOccupiedEndTimesDictionary,
        ...convertHoursListToOccupiedHoursDictionary(endTimesToOccupy, item.guid || item.tempGuid),
      };
    });

    setOccupiedStartTimes(newOccupiedStartTimesDictionary);
    setOccupiedEndTimes(newOccupiedEndTimesDictionary);
  };
  useEffect(manageOccupiedHourSlots, [localPlayListItems]);

  // We often need to check if items are the same and we need 2 id's due to the frontend add functionality
  // not getting us a real uuid. This function will help with comparing that.
  const compareItems = (item1, item2) => {
    if (!item1 || !item2) return false;
    if (item1.guid && item2.guid && item1.guid === item2.guid) return true;
    if (item1.tempGuid && item2.tempGuid && item1.tempGuid === item2.tempGuid) return true;
    return false;
  };

  const onSave = async () => {
    const payload = {
      guid,
      name: data.getPlaylist.name,
      items: localPlayListItems.map((item) => ({
        start: item.start,
        end: item.end,
        assetGuid: item.asset.guid,
        sound: item.sound,
      })),
    };
    await graphqlContext.client(editPlaylist, payload);
    loadPlaylistItems();
    setSavedStatus(true);
  };

  const onEdit = async (info) => {
    const newState = localPlayListItems.map((item) =>
      compareItems(item, currentFileInEditModal)
        ? {
            ...item,
            start: info.startTime,
            end: info.stopTime,
            sound: info.sound,
          }
        : item,
    );

    setLocalPlaylistItems(newState);
    setSavedStatus(false);
    setEditModalVisible(false);
  };

  const confirmDelete = () => {
    const newState = localPlayListItems.filter(
      (item) => !compareItems(item, currentFileInEditModal),
    );
    setLocalPlaylistItems(newState);
    setSavedStatus(false);
    setConfirmDeleteItemModalVisible(false);
    setEditModalVisible(false);
  };

  const onDelete = () => {
    setConfirmDeleteItemModalVisible(true);
  };

  const onAdd = async (info, asset) => {
    setAddModalVisible(false);
    setSavedStatus(false);
    setLocalPlaylistItems([
      ...localPlayListItems,
      {
        asset: asset,
        end: info.stopTime,
        start: info.startTime,
        sound: info.sound,
        tempGuid: (Math.random() * 1000 + Date.now()).toString(),
      },
    ]);
  };

  const handleAssetClick = (guid) => {
    viewFileInEditModal(guid);
  };

  const viewFileInModal = (guid) => {
    setCurrentFileInAddModal(assets.list.find((file) => file.guid === guid));
    setAddModalVisible(true);
  };

  const viewFileInEditModal = (guid) => {
    const fileToView = localPlayListItems.find(
      (file) => file.guid === guid || file.tempGuid === guid,
    );
    setCurrentFileInEditModal(fileToView);
    setEditModalVisible(true);
  };

  const goBack = () => route('/playlists');

  // If no unsaved status, go back otherwise prompt confirm.
  const handleGoBack = () => (savedStatus ? goBack() : setConfirmGoBackModalVisible(true));

  const currentlyViewedItemOccupiesSlot = (id) => {
    if (!currentFileInEditModal) {
      return false;
    }
    if (currentFileInEditModal.tempGuid) {
      return currentFileInEditModal.tempGuid === id;
    } else {
      return currentFileInEditModal.guid === id;
    }
  };

  const combinedTimeOptionsForEdit = {
    startTimes: timeOptions.map((timeOption) => ({
      ...timeOption,
      disabled:
        occupiedStartTimes[timeOption.name].occupied &&
        !currentlyViewedItemOccupiesSlot(occupiedStartTimes[timeOption.name].occupiedBy),
    })),
    stopTimes: timeOptions.map((timeOption) => ({
      ...timeOption,
      disabled:
        occupiedEndTimes[timeOption.name].occupied &&
        !currentlyViewedItemOccupiesSlot(occupiedEndTimes[timeOption.name].occupiedBy),
    })),
    localPlayListItems: localPlayListItems,
  };

  const combinedTimeOptionsForAdd = {
    startTimes: timeOptions.map((timeOption) => ({
      ...timeOption,
      disabled: occupiedStartTimes[timeOption.name]?.occupied,
    })),
    stopTimes: timeOptions.map((timeOption) => ({
      ...timeOption,
      disabled: occupiedEndTimes[timeOption.name]?.occupied,
    })),
    localPlayListItems: localPlayListItems,
  };

  const getPageHeading = () => (
    <>
      <FlexColumn>
        <div>{data && data.getPlaylist.name}</div>
        <TextSmall color={colors['lightGray']}>
          {`Screen Type: ${data && data.getPlaylist.screenType}`}
        </TextSmall>
      </FlexColumn>
    </>
  );

  return (
    <>
      <Sidebar width={'editPlaylistSidebarWidth'}>
        <ListContainer>
          <FilePicker files={assets.list} onClickAddItem={viewFileInModal} columns={2} />
        </ListContainer>
      </Sidebar>
      <MainContent size='editPlaylistSidebarWidth'>
        <FlexRowSpaceBetween>
          <FlexRow>
            <IconButton icon={'arrow-back'} onClick={handleGoBack} size='large' />
            <HorizontalSpace />
            <PageHeading style={{ marginBottom: '0' }}>
              {loading ? '...' : getPageHeading()}
            </PageHeading>
          </FlexRow>
          <FlexRowEnd>
            <Button text='Save' onClick={onSave} disabled={savedStatus} />
            <HorizontalSpace />
            <PlaylistPopupMenu onEditClick={() => setEditInfoModalVisible(true)} />
          </FlexRowEnd>
        </FlexRowSpaceBetween>
        <Space />
        <PlaylistSchedule playlistItems={localPlayListItems} handleAssetClick={handleAssetClick} />
        <AddFileModal
          visible={addModalVisible}
          asset={currentFileInAddModal}
          onAdd={onAdd}
          closeModal={() => setAddModalVisible(false)}
          timeOptions={combinedTimeOptionsForAdd}
        />
        <EditFileModal
          visible={editModalVisible}
          playlistItem={currentFileInEditModal}
          onSave={onEdit}
          onDelete={onDelete}
          closeModal={() => setEditModalVisible(false)}
          timeOptions={combinedTimeOptionsForEdit}
        />
        {data && (
          <EditPlaylistInfoModal
            visible={editInfoModalVisible}
            playlistData={data && data.getPlaylist}
            playlistGuid={guid}
            onSave={() => {
              console.log('save info');
            }}
            closeModal={() => setEditInfoModalVisible(false)}
            refetchPlaylist={refetch}
          />
        )}
        {/* Confirm modal for back button */}
        <ConfirmModal
          visible={confirmGoBackModalVisible}
          closeModal={() => setConfirmGoBackModalVisible(false)}
          title={'Confirm'}
          confirmText='Playlist has unsaved changes, are you sure you want to go back without saving?'
          confirmButtonText={'Yes'}
          handleConfirm={goBack}
        />
        {/* Confirm modal for delete */}
        <ConfirmModal
          visible={confirmDeleteItemModalVisible}
          closeModal={() => setConfirmDeleteItemModalVisible(false)}
          title={'Delete'}
          confirmText='Are you sure you want to delete?'
          confirmButtonText={'Yes'}
          handleConfirm={confirmDelete}
          relativeZindex={100}
          confirmButtonVariant={'delete'}
        />
      </MainContent>
    </>
  );
};

export default EditPlaylist;

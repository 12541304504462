import styled from '@emotion/styled';
import ModalComponent from '../../components/modal';
import Button from '../../components/button';
import { FlexColumn, FlexRowSpaceBetween } from '../../components/styled-components/layout';
import { colors } from '../../style/style-variables';
import { ErrorMessage } from '../../components/styled-components/typography';

const ConfirmText = styled.p`
    font-family: 'Roboto';
    color: ${colors['superLightGray']};
    font-weight: 300;
    margin-bottom: 64px;
`;

const ConfirmModal = ({
    visible,
    closeModal,
    title,
    confirmText,
    confirmButtonText,
    handleConfirm,
    relativeZindex,
    confirmButtonVariant,
}) => {

    const modalProps = {
        visible,
        title,
        closeModal,
        relativeZindex,
    };

    return (
        <ModalComponent { ...modalProps }>
            <FlexColumn>
                <ConfirmText>
                    {confirmText}
                </ConfirmText>
                <FlexRowSpaceBetween>
                    <Button text={'cancel'} onClick={closeModal} />
                    <Button
                        text={confirmButtonText}
                        onClick={handleConfirm}
                        type={confirmButtonVariant || 'primary'}
                    />
                </FlexRowSpaceBetween>
            </FlexColumn>
        </ModalComponent>
    );
};

export default ConfirmModal;

import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../../style/style-variables';
import { Label } from '../styled-components/forms';
import { HorizontalSpace } from '../styled-components/layout';

const StyledInput = styled.input`
  width: ${(props) => props.width || '100%'};
  background: transparent;
  border: 1px solid
    ${(props) => (props.errorMsg ? colors.error : props.disabled ? colors.disabled : colors.blue)};
  border-radius: 4px;
  padding: 0.7rem 1rem;
  box-sizing: border-box;
  color: ${(props) => (props.disabled ? colors.disabled : colors.superLightGray)};
  text-align: center;

  &:focus {
    outline: none;
  }

  &::-webkit-calendar-picker-indicator {
    filter: invert(100%);
  }
`;

export const TimeHolder = styled.div`
  display: flex;
  align-items: center;
`;

const TimePicker = (props) => {
  const { startProps, stopProps } = props;

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <div style={{ flex: 1 }}>
        <Label htmlFor='start-hour'>Start:</Label>
        <TimeHolder>
          <StyledInput
            type='time'
            id='start-time'
            value={startProps.value}
            onChange={startProps.onChange}
          />
        </TimeHolder>
      </div>
      <HorizontalSpace />
      <div style={{ flex: 1 }}>
        <Label htmlFor='end-hour'>Stop:</Label>
        <TimeHolder>
          <StyledInput
            type='time'
            id='end-time'
            value={stopProps.value}
            onChange={stopProps.onChange}
          />
        </TimeHolder>
      </div>
    </div>
  );
};

export default TimePicker;

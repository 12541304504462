import styled from "@emotion/styled"
import { colors } from '../../style/style-variables';

const StyledSpan = styled.span`
    width: 15px;
    height: 15px;
    border-radius: 360px;
    margin: ${p => p.useMargin ? '0 1rem' : '0' };
    background-color: ${props => props.color || colors['error']};
`;

const Dot = ({ color, useMargin = false }) => {
    return <StyledSpan color={color} useMargin={ useMargin }/>;
};

export default Dot;

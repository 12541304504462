import styled from '@emotion/styled';
import { useContext } from 'preact/hooks';
import ButtonComponent from '../../components/button';
import { HorizontalSpace } from '../../components/styled-components/layout';
import { LogoText, TextSmall } from '../../components/styled-components/typography';
import { AppContext } from '../../app-context/app-context';
import { StyledLeft, StyledRight, StyledHeader } from './header.style';
import Logo from '../logo/logo';
import { roles } from '../../constants';
import HeaderSettingsItemPopupMenu from './settings-popup';

const UserRoleText = styled(TextSmall)`
  text-transform: capitalize;
`;

const Header = () => {
  const { user } = useContext(AppContext);

  if (!user) return null;

  const handleLogout = async () => {
    const response = await fetch('/logout');
    if (response.ok) {
      location.reload();
    }
  };

  const userRole = roles.find(({ id }) => id === user.role_id)?.name ?? null;

  return (
    <StyledHeader>
      <StyledLeft>
        <Logo />
        <LogoText>Interactive Content Solution – Cloud</LogoText>
      </StyledLeft>
      <StyledRight>
        <TextSmall>{user.email}</TextSmall>
        <HorizontalSpace />
        <UserRoleText>{userRole}</UserRoleText>
        <HorizontalSpace />
        <HeaderSettingsItemPopupMenu />
        <HorizontalSpace />
        <ButtonComponent theme='small-outlined' onClick={handleLogout}>
          Log out
        </ButtonComponent>
      </StyledRight>
    </StyledHeader>
  );
};

export default Header;

import { useContext, useState } from 'preact/hooks';
import { route } from 'preact-router';

import { PopupMenuItem } from '../../components/styled-components/typography';
import { AppContext } from '../../app-context/app-context';
import { isActionAllowed } from '../../helpers/roles';
import { PERMISSIONS } from '../../constants';
import Popover from '../../components/popover/popover';
import IconButtonComponent from '../../components/icon-button';

const LocationItemPopupMenu = ({ guid, handleRenameClick, handleDeleteClick }) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const {user} = useContext(AppContext)

    const navigateToLocation = () => route(`/installations/${guid}`);

    const popoverContent = (
        <div>
            <PopupMenuItem onClick={navigateToLocation}>
                Edit
            </PopupMenuItem>
            {isActionAllowed(PERMISSIONS.EDIT_LOCATION, user?.role_id) && 
            <PopupMenuItem onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                handleRenameClick(guid);
            }}>
                Rename
            </PopupMenuItem>
            }
            {isActionAllowed(PERMISSIONS.DELETE_LOCATION, user?.role_id) && 
                <PopupMenuItem 
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleDeleteClick(guid);
                    }}>
                    Delete
                </PopupMenuItem>
            }
        </div>
    );

    const iconProps = {
        icon: 'more',
        style: 'transparent',
        onClick: e => {
            e.stopPropagation();
            e.preventDefault();
            setPopoverOpen(!popoverOpen);
        },
    };

    const popoverProps = {
        visible: popoverOpen,
        content: popoverContent,
        onClose: () => {
            setPopoverOpen(false);
        },
    };

    return (
        <Popover { ...popoverProps }>
            <IconButtonComponent { ...iconProps } />
        </Popover>
    );
};

export default LocationItemPopupMenu;

import styled from '@emotion/styled';
import { colors } from '../../style/style-variables';
import { Label, FormGroup } from '../styled-components/forms';
import { ErrorMessageForInput } from '../styled-components/typography';
import PropTypes from 'prop-types';

const StyledSelect = styled.select`
  width: ${(props) => props.width || '100%'};
  max-width: ${(props) => props.maxWidth || '100%'};
  background: transparent;
  border: 1px solid ${(props) => (props.errorMsg ? colors.error : colors['blue'])};
  border-radius: 4px;
  padding: 0.7rem 2rem 0.7rem 1rem;
  box-sizing: border-box;
  color: ${colors['superLightGray']};
  appearance: none;
  text-transform: capitalize;

  &:focus {
    outline: none;
  }
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2U9IiNiYmIiPjxwYXRoIGQ9Ik02IDlsNiA2IDYtNiIvPjwvc3ZnPg==);
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 50%;
`;

const Option = styled.option`
  background-color: ${colors.darkGray};
  text-transform: capitalize;
`;

export const Select = ({
  label,
  options,
  value,
  onChange,
  placeholder,
  onBlur,
  width,
  maxWidth,
  errorMsg,
}) => {
  const selectProps = {
    value,
    onChange,
    onBlur,
    width,
    maxWidth,
    errorMsg,
    // defaultValue
  };

  return (
    <FormGroup>
      {label ? <Label>{label}</Label> : null}
      <StyledSelect {...selectProps}>
        {placeholder ? <Option value=''>{placeholder}</Option> : null}
        {options.map(({ name, key, disabled }) => (
          <Option key={key} value={key} disabled={disabled}>
            {name}
          </Option>
        ))}
      </StyledSelect>
      {!!errorMsg && <ErrorMessageForInput>{errorMsg}</ErrorMessageForInput>}
    </FormGroup>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  defaultValue: PropTypes.string,
  errorMsg: PropTypes.string,
};

import { gql } from 'graphql-request';

export const MUTATION_CREATE_SCREENING = gql`
    mutation createScreening(
        $input: ScreeningInput!
    ) {
        createScreening(
            input: $input
        ) {
            guid
        }
    }
`;

export const MUTATION_EDIT_SCREENING = gql`
    mutation editScreening($input: EditScreeningInput!) {
        editScreening(input: $input) {
            guid
        }
    }
`;

export const MUTATION_DELETE_SCREENING = gql`
    mutation deleteScreening($guid: String!) {
        deleteScreening(guid: $guid)
    }
`;

export const QUERY_GET_PLAYLISTS_AND_ASSETS = gql`
    query getPlaylists {
        getPlaylists {
            guid
            name  
            items {
                start
                end
                asset {
                    name
                    thumbnail
                    filename
                    size
                    guid
                }
            }
        }
        getAssets {
            guid
            filename
            key
            thumbnail
            size
            name
        }
    }
`;

export const QUERY_GET_LOCATION = gql`
    query getLocation($guid: String!) {
        getLocation(guid: $guid) {
            guid
            name
            screenings {
                guid
                start
                end
                type
                playlist {
                    guid
                    name
                }
                asset {
                    guid
                    name
                }
            }
        }
    }
`;



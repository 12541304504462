import styled from '@emotion/styled';
import { colors } from '../../style/style-variables';
import { Label, FormGroup } from '../styled-components/forms';
import { ErrorMessageForInput } from '../styled-components/typography';

export const StyledInput = styled.input`
  width: ${(props) => props.width || '100%'};
  ${(p) => (p.maxWidth ? 'max-width: 840px' : '')};
  background: transparent;
  border: 1px solid ${(props) => (props.hasError ? colors.error : colors.blue)};
  border-radius: 4px;
  padding: 0 1rem;
  line-height: 3;
  box-sizing: border-box;
  color: ${colors['superLightGray']};

  &:focus {
    outline: none;
  }
`;

export const Input = ({
  label,
  value,
  onChange,
  onBlur,
  maxWidth,
  width,
  type = 'text',
  disabled = false,
  errorMsg,
}) => {
  return (
    <FormGroup>
      {label ? <Label>{label}</Label> : null}
      <StyledInput
        hasError={!!errorMsg}
        width={width}
        type={type}
        value={value || ''}
        onChange={onChange}
        onBlur={onBlur}
        maxWidth={maxWidth}
        disabled={disabled}
      />
      {!!errorMsg && <ErrorMessageForInput>{errorMsg}</ErrorMessageForInput>}
    </FormGroup>
  );
};

import styled from '@emotion/styled';

import { colors } from '../../../style/style-variables';

export const StyledTop = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px grey solid;
  width: 100%;
  padding: 1.5rem 2rem 0rem 2rem;
`;

export const StyledItemOption = styled.div`
  margin-right: 0.5rem;
  color: ${colors['superLightGray']};
  border-bottom: 3px solid ${(p) => (p.active ? colors['blue'] : 'unset')};
  padding: 0.5rem 1.2rem;
  margin-bottom: 0.2rem;
  border-radius: 1px;
  cursor: pointer;
`;

export const StyledMultiItemPicker = styled.div``;

export const StyledList = styled.div`
  padding: 2rem 2rem 2rem;
  height: 90vh;
  overflow-y: scroll;
  box-shadow: inset 1px 1px 6px rgb(0 0 0 / 27%);
`;

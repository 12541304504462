import styled from '@emotion/styled';
import Icons from '../icons';
import { colors } from '../../style/style-variables';
import PropTypes from 'prop-types';

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.height || '100%'};
  background-color: ${colors['greenishDarkGray']};
`;

const iconStyle = {
  width: '24px',
  height: '24px',
};

const ThumbnailMissing = ({ height }) => (
  <IconContainer height={height}>
    <Icons name='no-thumbnail' style={iconStyle} />
  </IconContainer>
);

ThumbnailMissing.propTypes = {
  height: PropTypes.string,
};

export default ThumbnailMissing;

import styled from '@emotion/styled';
import { colors } from '../../style/style-variables';
import Button from '../button';
import { SectionHeading, Text } from '../styled-components/typography';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 100vh;
  width: 100vw;
`;

const Title = styled(SectionHeading)`
  font-size: 2rem;
  margin: 0;
`;

const Message = styled(Text)`
  max-width: 20rem;
  text-align: center;
`;

export const ErrorMessage = () => (
  <Wrapper>
    <Title color="superLightGray">Oops, something went wrong.</Title>
    <Message color="superLightGray">
      You seem to have stumbled upon an error. Try refreshing the page, or try again later.
    </Message>
    <Button
      theme="outlined"
      onClick={() => {
        location.href = '/';
      }}
    >
      Take me back to the home page
    </Button>
  </Wrapper>
);

import PropTypes from 'prop-types';
import IconButton from '../../icon-button';
import Thumbnail from '../../thumbnail/thumbnail';
import ThumbnailMissing from '../../thumbnail/thumbnail-missing';
import Thumbnails from '../../thumbnails/thumbnails';
import {
  FileItem,
  Container,
  Name,
  FileInfo,
  StyledContent,
  GridContainer,
} from './file-picker.style';



const FilePickerItem = ({
  type,
  itemWidth,
  name,
  filename,
  items,
  thumbnail,
  guid,
  onClickAddItem,
}) => {
  let thumbnailItem = <Thumbnail image={'/thumbnail/' + thumbnail} />;

  if (!thumbnail && items) {
    const thumbnails = items.map((item) =>
      item.asset ? '/thumbnail/' + item.asset.thumbnail : null,
    );
    thumbnailItem = <Thumbnails list={thumbnails} />;
  } else if (!thumbnail) {
    thumbnailItem = <ThumbnailMissing height={"100px"} />;
  }

  return (
    <FileItem width={itemWidth}>
      {thumbnailItem}
      <StyledContent>
        <Container>
          <Name>{name}</Name>
          <FileInfo>{filename || type}</FileInfo>
        </Container>
        <IconButton icon={'add'} onClick={() => onClickAddItem(guid)} style='light' />
      </StyledContent>
    </FileItem>
  );
};

FilePickerItem.propTypes = {
  type: PropTypes.string,
  itemWidth: PropTypes.string,
  name: PropTypes.string,
  filename: PropTypes.string,
  items: PropTypes.array,
  thumbnail: PropTypes.string,
  guid: PropTypes.string,
  onClickAddItem: PropTypes.func,
};

export const FilePicker = ({ files, type, onClickAddItem, columns = 1 }) => {
  return (
    <GridContainer columns={columns}>
      {files.map((file, index) => (
        <FilePickerItem key={index} {...file} type={type} onClickAddItem={onClickAddItem} />
      ))}
    </GridContainer>
  );
};

FilePicker.propTypes = {
  files: PropTypes.array,
  type: PropTypes.string,
  onClickAddItem: PropTypes.func,
  columns: PropTypes.number,
};

import styled from "@emotion/styled";
import { route } from "preact-router";
import { useContext } from "preact/hooks";
import { AppContext } from "../../app-context/app-context";
import MainContent from "../../components/main-content/main-content";
import { colors } from "../../style/style-variables";

const Styled404 = styled.span`
    font-size: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex: 1;
    color: ${colors.superLightGray}
`;

const Four04 = () => {

    const { user } = useContext(AppContext);

    if (!user) {
        route('/login');
    }

    return (
        <MainContent>
            <Styled404>404</Styled404>
        </MainContent>
    );
};

export default Four04;

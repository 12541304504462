import { newOccupiedTimesBetweenStartAndStop, endTimeBeforeStartTime } from './time-helpers';

export const getValidStatus = (formValues, timeOptions, id) => {
  let addButtonDisabled = false;
  let errorMessage = null;

  if (!(formValues['startTime'] && formValues['stopTime'])) {
    errorMessage = 'Please select a start and stop time!';
    addButtonDisabled = true;
  } else if (endTimeBeforeStartTime(formValues['stopTime'], formValues['startTime'])) {
    errorMessage = 'The stop time must be after the start time!';
    addButtonDisabled = true;
  } else if (formValues['startTime'] === formValues['stopTime']) {
    errorMessage = 'Start and end time cannot be the same!';
    addButtonDisabled = true;
  } else if (
    newOccupiedTimesBetweenStartAndStop(
      formValues['stopTime'],
      formValues['startTime'],
      timeOptions,
      id,
    )
  ) {
    errorMessage = 'Not all hour slots between selected start and stop times is vacant!';
    addButtonDisabled = true;
  }

  return {
    addButtonDisabled,
    errorMessage,
  };
};

import styled from '@emotion/styled';
import { colors } from '../../style/style-variables';

export const Label = styled.p`
    font-family: 'Roboto';
    font-size: 0.8rem;
    color: ${colors['lightGray']};
`;

export const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { colors } from '../../style/style-variables';

const keyframesShow = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

export const StyledThumbnailImage = styled.img`
    width: 100%;
    height: ${props => props.height || 100}px;
    background-color: ${colors['darkPurpleGray']};
    object-fit: cover;
`;

export const StyledThumbnail = styled.div`
    width: 100%;
    height: ${props => props.height || 100}px;
    position: relative;
    overflow: hidden;
    opacity: 0;
    animation-delay: 1s;
    animation: ${keyframesShow} .3s ease-out forwards;
`;

import { colors, sizes } from '../../style/style-variables';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { css } from '@emotion/css';
import { Link } from 'preact-router/match';
import { useAppContext } from '../../app-context/app-context';
import { useEffect, useState } from 'preact/hooks';
import { ROLES } from '../../constants';
import { isRoleAllowed } from '../../helpers/roles';

const StyledSidebar = styled.div`
    padding: 5rem 2rem 2rem;
    width: ${sizes.menuWidth};
    background: ${colors['darkGray']};
    top: 0;
    left: 0;
    bottom: 0;
    minHeight: 100%;
    position: fixed;
`;

const Menu = styled.ul`
    list-style-type: none;
    padding-left: 0;
    font-size: 16px;
`;

const linkStyle = css`
    font-family: roboto;
    color: lightGray;
    padding: 12px 12px;
    margin-bottom: 12px;
    font-weight: 300;
    border-radius: 6px;
    cursor: pointer;
    text-decoration: none;
    display: block;
    &.active {
        background-color: ${colors['blue']};
    }
`;

const keyframesShow = keyframes`
    from {
        margin-left: -364px;
    }
  
    to {
        margin-left: 0px;
    }
`;

const keyframesHide = keyframes`
    from {
        margin-left: 0px;
    }
    to {
        margin-left: -364px;
    }
`;



const cssVisibleMenu = css`
    animation: ${keyframesShow} .3s ease-out forwards;
`;

const cssHiddenMenu = css`
    animation: ${keyframesHide} .3s ease-out forwards;
`;

const MenuComponent = () => {
    const { menuVisible } = useAppContext();
    const { user } = useAppContext();

    // cheap trick to force preact router links to highlight themselves
    // as appropriate when logging in
    const [dudState, setDudState] = useState(1);
    useEffect(() => {
        setDudState(dudState + 1);
    }, [user]);

    if (!user || !menuVisible) return null;

    return (
        <StyledSidebar className={ menuVisible ? cssVisibleMenu : cssHiddenMenu }>
            <nav>
                <Menu>
                    <li><Link class={linkStyle} activeClassName="active" href="/">Installations</Link></li>
                    <li><Link class={linkStyle} activeClassName="active" href="/playlists">Playlists</Link></li>
                    {isRoleAllowed(user.role_id, ROLES.ADMIN, ROLES.ORGANIZER)  && <li><Link class={linkStyle} activeClassName="active" href="/management">Management</Link></li>}
                    <li><Link class={linkStyle} activeClassName="active" href="/content">Content</Link></li>
                </Menu>
            </nav>
        </StyledSidebar>
    );
};

export default MenuComponent;

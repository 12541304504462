import { useEffect, useState } from 'preact/hooks';

const useFetch = (url, options = {}) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    const fire = async () => {

        setLoading(true);

        try {
            const response = await fetch(url, options);
            const json = await response.json();
            setLoading(false);
            setData(json);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    }

    useEffect(fire, []);

    return { loading, error, data };
};

export default useFetch;

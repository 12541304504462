import { useState } from 'preact/hooks';
import { gql } from 'graphql-request';
import { useMutation } from '../../graphql-context/graphql-context';
import { route } from 'preact-router';
import styled from '@emotion/styled';
import Popover from '../../components/popover/popover';
import ConfirmModal from '../../components/confirm-modal';
import IconButtonComponent from '../../components/icon-button';
import { colors } from '../../style/style-variables';
import { PopupMenuItem } from '../../components/styled-components/typography';

const mutation = gql`
    mutation deletePlaylist($guid: String!) {
        deletePlaylist( guid: $guid )
    }
`;

const PlaylistCardPopoverMenu = ({ name, guid, reload }) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);

    const [deletePlaylist, { loading, error }] = useMutation(mutation, {
        variables: {
            guid,
        },
        onCompleted: () => {
            setConfirmModalVisible(false);
            reload();
        },
    });

    const handleDeletePlaylist = () => {
        deletePlaylist();
    };

    const navigateToPlaylist = () => {
        route(`playlist/${guid}`);
    };

    const popoverContent = (
        <div>
            <PopupMenuItem onClick={navigateToPlaylist}>
                Edit
            </PopupMenuItem>
            <PopupMenuItem onClick={() => {
                setConfirmModalVisible(true);
            }}>
                Delete
            </PopupMenuItem>
        </div>
    );

    const iconProps = {
        icon: 'more',
        style: 'transparent',
        onClick: () => {
            setPopoverOpen(!popoverOpen);
        },
    };

    const popoverProps = {
        visible: popoverOpen,
        content: popoverContent,
        onClose: () => {
            setPopoverOpen(false);
        },
    };

    return (
        <Popover { ...popoverProps }>
            <IconButtonComponent { ...iconProps } />
            <ConfirmModal
                visible={confirmModalVisible}
                closeModal={() => setConfirmModalVisible(false)}
                title="Confirm deletion"
                confirmText={`Do you want to delete playlist ${name}?`}
                confirmButtonText="Delete"
                handleConfirm={handleDeletePlaylist}
                error={error?.response?.errors[0]?.message}
                loading={loading}
                confirmButtonVariant={'delete'}
            />
        </Popover>
    );
};

export default PlaylistCardPopoverMenu;
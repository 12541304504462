import { gql } from 'graphql-request';
import { useContext, useEffect, useState } from 'preact/hooks';
import { PageHeading } from '../../components/styled-components/typography';
import { FlexRowSpaceBetween, WrappingFlexRow, Space } from '../../components/styled-components/layout';
import Button from '../../components/button';
import PlaylistCard from './playlist-card';
import CreatePlaylistModal from './create-playlist-modal';
import { ClientContext } from '../../graphql-context/graphql-context';
import MainContent from '../../components/main-content/main-content';
import {AppContext} from '../../app-context/app-context';

const queryGetPlaylists = gql`
    query GetPlaylists {
        getPlaylists {
            guid
            name
            screenType
            interactivity
            items {
                asset {
                    thumbnail
                }
            }
        }
    }
`;

const mutationDeleteLocation = gql`
    mutation DeleteLocation($guid: String!) {
        deleteLocation(guid: $guid)
    }
`;

const Playlists = () => {

    const graphqlContext = useContext(ClientContext);
    const { setMenuVisible } = useContext(AppContext);

    const [playlists, setPlaylists] = useState({ count: 0, limit: 100, offset: 0, list: []});
    const [visible, setVisible] = useState(false);

    const reload = async () => {
        const result = await graphqlContext.client(queryGetPlaylists);
        setPlaylists({list: result.getPlaylists});
    };

    const remove = async (guid) => {
        await graphqlContext.client(mutationDeleteLocation, { guid });
        reload();
    }

    useEffect(() => {
        setMenuVisible(true);
        reload();
    }, []);

    return (
        <MainContent>
            <FlexRowSpaceBetween>
                <PageHeading>Playlists</PageHeading>
                <Button
                    text="Create playlist"
                    icon="add"
                    onClick={() => setVisible(true)}
                />
            </FlexRowSpaceBetween>
            <Space />
            <WrappingFlexRow>
                { playlists.list.map( playlist => <PlaylistCard {...playlist} reload={reload} />)}
            </WrappingFlexRow>
            <CreatePlaylistModal onClose={() => reload()} visible={visible} closeModal={() => setVisible(false)} />
        </MainContent>
    );
};

export default Playlists;

import Thumbnail from '../thumbnail/thumbnail';
import ThumbnailMissing from '../thumbnail/thumbnail-missing';

import { StyledList, StyledListItem } from './thumbnails.style';

const Thumbnails = ({list}) => {

    const uniqueList = [ ...new Set(list) ].filter(Boolean);

    const thumbnailList = uniqueList.map((thumbnail, index) => {
        const left = `${100 - (uniqueList.length - index) / uniqueList.length * 100}%`;
        return (
            <StyledListItem style={{left, zIndex: index}}>
                <Thumbnail image={thumbnail} />
            </StyledListItem>
        );
    });

    if( !thumbnailList.length ) {
        return (
            <StyledList>
                <ThumbnailMissing />
            </StyledList>
        )
    }

    return (
        <StyledList>
            { thumbnailList }
        </StyledList>
    );
};

export default Thumbnails;

import { StyledThumbnailImage, StyledThumbnail } from './thumbnail.style';

const Thumbnail = ({ image, height }) => {
    return (
        <StyledThumbnail height={height} >
            <StyledThumbnailImage src={image} height={height} />
        </StyledThumbnail>
    );
};

export default Thumbnail;

export default [
    {
        name: 'Projector',
        key: 'projector',
    },
    {
        name: 'TV',
        key: 'tv',
    },
    {
        name: 'LED',
        key: 'led',
    },
];

import axios from 'axios';
import { useState } from 'preact/hooks';
import { Controller, useForm } from 'react-hook-form';
import ButtonComponent from '../../../components/button';
import { Input } from '../../../components/form';
import { Row, Space } from '../../../components/styled-components/layout';
import { SectionHeading, Text, TextSmall } from '../../../components/styled-components/typography';
import { getErrorMessage } from '../../../helpers/get-error-message';

const ForceNewPassword = ({ setState, email, session, setPassword }) => {
  const {
    control,
    handleSubmit,
    reset,
    clearErrors,
    getValues,
    resetField,
    setError: setFormError,
    formState: { errors },
  } = useForm();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  async function formSubmit(formData) {
    const { newPassword, confirmNewPassword } = formData;
    if (newPassword !== confirmNewPassword) {
      setFormError('confirmNewPassword', { type: 'validate', message: 'Please make sure your passwords match.' });
      resetField('newPassword');
      resetField('confirmNewPassword');
      return;
    }

    try {
      setLoading(true);
      const { data } = await axios.post('/force-new-password', {
        email,
        session,
        password: newPassword,
      });

      reset();
      clearErrors();
      setState(data.status);
      setPassword(newPassword);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(getErrorMessage(error));
    }
  }

  return (
    <>
      <SectionHeading>You need to update your password</SectionHeading>
      <TextSmall>{email}</TextSmall>

      <form onSubmit={handleSubmit(formSubmit)}>
        <Controller
          control={control}
          rules={{
            required: 'New password is required',
            minLength: { value: 6, message: 'Minimum length on your password is 6 characters' },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              errorMsg={errors.password?.message}
              type="password"
              label="New password"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            ></Input>
          )}
          name="newPassword"
        />
        <Controller
          control={control}
          rules={{
            required: 'Confirm new password is required',
            validate: (value) => value === getValues('newPassword') || 'Please make sure your passwords match.',
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              errorMsg={errors.confirmPassword?.message}
              type="password"
              label="Confirm new password"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            ></Input>
          )}
          name="confirmNewPassword"
        />

        <Row justifyContent="space-between">
          <ButtonComponent loading={loading} type="submit">
            Confirm
          </ButtonComponent>
        </Row>
      </form>
      {error && (
        <>
          <Space size={1} />
          <Text color="error">{error}</Text>
        </>
      )}
    </>
  );
};

export default ForceNewPassword;

import { useState } from 'preact/hooks';
import { FilePicker } from '../../form';
import { HorizontalSpace } from '../../styled-components/layout';
import { StyledList, StyledTop, StyledItemOption, StyledMultiItemPicker }  from './multi-item-picker.style';

export const MultiItemPicker = ({playlists, assets, onAddAsset, onAddPlaylist }) => {

    const [itemsToShow, setItemsToShow] = useState('playlists');

    const items = itemsToShow === 'playlists' ?
        (<FilePicker itemWidth="big" type="Playlist" files={playlists} onClickAddItem={onAddPlaylist} />) :
        (<FilePicker files={assets} type="Asset" onClickAddItem={onAddAsset} />) ;

    return (
        <StyledMultiItemPicker>
            <StyledTop>
                <StyledItemOption active={itemsToShow === 'playlists'} onClick={ () => setItemsToShow('playlists') }>Playlists</StyledItemOption>
                <HorizontalSpace />
                <StyledItemOption active={itemsToShow === 'assets'} onClick={ () => setItemsToShow('assets') }>Assets</StyledItemOption>
            </StyledTop>
            <StyledList>
                {items}
            </StyledList>
        </StyledMultiItemPicker>
    );
};


import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { sizes, colors } from '../../style/style-variables';

const keyframesShow = keyframes`
    from {
        transform: translate3d(-500px,0,0);
    }
  
    to {
        transform: translate3d(0px,0,0);
    }
`;

const Sidebar = styled.div`
    position: fixed;
    padding: ${p => p.padding ? '7rem 2rem 2rem' : '70px 0 0' };
    width: ${p => sizes[p.width] || sizes.sidebarWidth};
    background: ${colors['darkGray']};
    top: 0;
    bottom: 0;
    left: 0px;
    min-height: 100%;
    transform: translate3d(-500px,0,0);
    animation: ${keyframesShow} .3s ease-out forwards;
`;

const SidebarComponent = ({ children, ...rest }) => (
    <Sidebar { ...rest } >
        { children }
    </Sidebar>
);

export default SidebarComponent;

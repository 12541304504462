import { createContext } from 'preact';
import { useContext, useState } from 'preact/hooks';

const useAppContext = () => {
    const { user, setUser, menuVisible, setMenuVisible } = useContext(AppContext);
    return { user, setUser, menuVisible, setMenuVisible };
};

const AppContext = createContext({ });

const AppContextProvider = ({children, defaultUser}) => {

    const [menuVisible, setMenuVisible] = useState(true);
    const [user, setUser] = useState(defaultUser);

    const context = {
        menuVisible,
        setMenuVisible,
        user,
        setUser,
    }

    return (
        <AppContext.Provider value={context}>{ children }</AppContext.Provider>
    );

};

export {
    AppContext,
    AppContextProvider,
    useAppContext,
};




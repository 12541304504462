import { useContext, useState } from 'preact/hooks';

import Popover from '../../components/popover/popover';
import ConfirmModal from '../../components/confirm-modal';
import IconButtonComponent from '../../components/icon-button';
import { PopupMenuItem } from '../../components/styled-components/typography';
import OrganizationModal from './OrganizationModal';
import { AppContext } from '../../app-context/app-context';
import { isActionAllowed } from '../../helpers/roles';
import { PERMISSIONS } from '../../constants';
import { useDeleteOrganization, useEditOrganization } from '../../hooks/organization.hooks';

const OrganizationCardPopoverMenu = ({ name, uuid, reload }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const { user } = useContext(AppContext);

  const { mutate: mutateDeleteOrganization } = useDeleteOrganization();
  const { mutate: mutateEditOrganization } = useEditOrganization();

  const handleDeleteOrganization = async () => {
    await mutateDeleteOrganization(uuid);

    setConfirmModalVisible(false);
  };

  const handleEditOrganization = async (variables) => {
    await mutateEditOrganization({ ...variables, uuid });

    toggleEditModal();
  };

  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  const popoverContent = (
    <div>
      {isActionAllowed(PERMISSIONS.EDIT_ORGANIZATION, user?.role_id) && (
        <PopupMenuItem onClick={toggleEditModal}>Edit</PopupMenuItem>
      )}
      {isActionAllowed(PERMISSIONS.DELETE_ORGANIZATION, user?.role_id) && (
        <PopupMenuItem
          onClick={() => {
            setConfirmModalVisible(true);
          }}
        >
          Delete
        </PopupMenuItem>
      )}
    </div>
  );

  const iconProps = {
    icon: 'more',
    style: 'transparent',
    onClick: () => {
      setPopoverOpen(!popoverOpen);
    },
  };

  const popoverProps = {
    visible: popoverOpen,
    content: popoverContent,
    onClose: () => {
      setPopoverOpen(false);
    },
  };

  return (
    <>
      <Popover {...popoverProps}>
        <IconButtonComponent {...iconProps} />
        <ConfirmModal
          visible={confirmModalVisible}
          closeModal={() => setConfirmModalVisible(false)}
          title="Confirm deletion"
          confirmText={`Do you want to delete organization ${name}?`}
          confirmButtonText="Delete"
          handleConfirm={handleDeleteOrganization}
          // error={error?.response?.errors[0]?.message}
          // loading={loading}
          confirmButtonVariant={'delete'}
        />
      </Popover>
      <OrganizationModal
        formValues={{ name }}
        visible={isEditModalOpen}
        onSubmit={handleEditOrganization}
        closeModal={() => {
          toggleEditModal();
        }}
      />
    </>
  );
};

export default OrganizationCardPopoverMenu;

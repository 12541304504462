export const PERMISSIONS = {
  CREATE_LOCATION: 'create_location',
  DELETE_LOCATION: 'delete_location',
  EDIT_LOCATION: 'edit_location',
  GET_USERS: 'get_users',
  CREATE_USER: 'create_user',
  DELETE_USER: 'delete_user',
  SET_USER_ORGANIZATION: 'set_user_organization',
  SET_USER_ROLE: 'set_user_role',
  CREATE_ORGANIZATION: 'create_organization',
  DELETE_ORGANIZATION: 'delete_organization',
  GET_ORGANIZATIONS: 'get_organizations',
  GET_ORGANIZATION: 'get_organization',
  EDIT_ORGANIZATION: 'edit_organization',
  GET_PERMISSION_MATRIX: 'get_permission_matrix',
  GET_ROLES: 'get_roles',
};

const permissions = Object.values(PERMISSIONS);

export const ROLES = {
  ADMIN: 1,
  ORGANIZER: 2,
  USER: 3,
};

export const roles = [
  { id: ROLES.ADMIN, name: 'admin' },
  {
    id: ROLES.ORGANIZER,
    name: 'organizer',
  },
  { id: ROLES.USER, name: 'user' },
];

export const rolesWithPermissions = {
  [`${ROLES.ADMIN}`]: [...permissions],
  [`${ROLES.ORGANIZER}`]: [PERMISSIONS.GET_USERS, PERMISSIONS.CREATE_USER, PERMISSIONS.DELETE_USER, PERMISSIONS.GET_ROLES, PERMISSIONS.SET_USER_ROLE],
  [`${ROLES.USER}`]: [],
};

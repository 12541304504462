import styled from '@emotion/styled';
import { colors } from '../../style/style-variables';
import Icons from '../icons';

const IconButton = styled.button`
    background-color: ${colors['darkPurpleGray']};
    border-radius: 24px;
    padding: ${ p => p.size === 'large' ? '6px' : '2px'};
    border: 0;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & > svg {
        fill: ${colors['superLightGray']};
    }

    &:hover {
        background-color: #6a6ea5;
    }

    &:focus {
        outline: 0;
    }

    &:active {
        background-color: white;
    }

    &:disabled {
        background-color: ${colors['lightGray']}
    }

    &:disabled:hover {
        background-color: ${colors['lightGray']}
    }
`;

const styleVariants = {
    dark: styled(IconButton)`
    `,
    light: styled(IconButton)`
        background-color: ${colors['superLightGray']};
        & > svg {
            fill: ${colors['darkPurpleGray']};
            transition: fill 0.3s ease;
        }

        &:hover {
            background-color: #96a0ce;
            fill: white;

            & > svg {
                fill: ${colors['superLightGray']};
            }
        }

        &:active {
            background-color: white;
        }
    `,
    transparent: styled(IconButton)`
        background-color: transparent;
    `,
};

const iconStyle = {
    height: '24px',
    width: '24px',
};

const IconButtonComponent = ({ onClick, icon, style, size }) => {
    const IconButton = styleVariants[ style ? style : 'dark' ];

    return (
        <IconButton onClick={onClick} size={size}>
            <Icons name={icon} style={iconStyle} />
        </IconButton>
    );
};

export default IconButtonComponent;
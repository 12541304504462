import styled from '@emotion/styled';
import { useState } from 'preact/hooks';
import { colors } from '../../style/style-variables';

const size = 20;

const Input = styled.input`
    position: relative;
    opacity: 0;
    width: 0;
`;

const Checkmark = styled.span`
    height: ${size}px;
    width: ${size}px;
    border: 2px solid ${colors.lightGray};
    position: absolute;
    top: -2px;
    left: 0;
    border-radius: 3px;
    background-color: ${props => props.checked ? 'white' : 'transparent'};
`;

const Container = styled.label`
    position: relative;
    user-select: none;
    cursor: pointer;
    height: ${size}px;
    padding-left: 1rem;
    margin: 0 1rem 0 0.5rem;
`;

const Checkbox = (props) => {

    const { checked, onChange, label } = props;

    const inputProps = {
        type: 'checkbox',
        checked,
        onChange: (event) => {
            if (typeof onChange === 'function') {
                onChange(event)
            }
        }
    };

    return (
        <Container>
            <Input { ...inputProps} />
            <Checkmark checked={checked} />
            {label || ''}
        </Container>
    );

};

export default Checkbox;

import styled from '@emotion/styled';
import ButtonComponent from '../../components/button';
import ModalComponent from '../../components/modal';
import { FlexColumn, FlexRowSpaceBetween } from '../../components/styled-components/layout';
import { useDeleteUser } from '../../hooks/user.hooks';
import { colors } from '../../style/style-variables';

const ConfirmText = styled.p`
  font-family: 'Roboto';
  color: ${colors['superLightGray']};
  font-weight: 300;
  margin-bottom: 64px;
`;

const ConfirmDeleteUserModal = ({ isVisible, onClose, onSubmit, userEmail }) => {
  const { mutate: mutateDeleteUser } = useDeleteUser();

  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleOnSubmit = async () => {
    await mutateDeleteUser({ email: userEmail, full: true });
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <ModalComponent visible={isVisible} title="Delete User" closeModal={onClose}>
      <FlexColumn>
        <ConfirmText>Are you sure you want to delete user "{userEmail}"?</ConfirmText>
        <FlexRowSpaceBetween>
          <ButtonComponent text={'cancel'} onClick={handleOnClose} />
          <ButtonComponent text={'delete'} onClick={handleOnSubmit} theme={'delete'} />
        </FlexRowSpaceBetween>
      </FlexColumn>
    </ModalComponent>
  );
};

export default ConfirmDeleteUserModal;

import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getUsers, deleteUser, adminCreateUser, setUserOrganization, setUserRole } from '../api';
import { ORGANIZATIONS_STATE_KEY } from './organization.hooks';

const USERS_STATE_KEY = 'users';

export const useGetUsers = () => useQuery(USERS_STATE_KEY, getUsers);

export const useDeleteUser = () => {
  const cache = useQueryClient();

  return useMutation(deleteUser, {
    onSuccess: () => {
      cache.invalidateQueries(USERS_STATE_KEY);
      cache.invalidateQueries(ORGANIZATIONS_STATE_KEY);
    },
  });
};

export const useAdminCreateUser = (onSuccess, onError) => {
  const cache = useQueryClient();

  return useMutation(adminCreateUser, {
    onSuccess: () => {
      cache.invalidateQueries(USERS_STATE_KEY);
      cache.invalidateQueries(ORGANIZATIONS_STATE_KEY);
      onSuccess?.();
    },
    onError
  });
};

export const useSetUserOrganization = () => {
  const cache = useQueryClient();

  return useMutation(setUserOrganization, {
    onSuccess: () => {
      cache.invalidateQueries(USERS_STATE_KEY);
      cache.invalidateQueries(ORGANIZATIONS_STATE_KEY);
    },
  });
};

export const useSetUserRole = () => {
  const cache = useQueryClient();

  return useMutation(setUserRole, {
    onSuccess: () => {
      cache.invalidateQueries(USERS_STATE_KEY);
    },
  });
};

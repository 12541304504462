import { gql } from 'graphql-request';
import { useState, useEffect, useContext } from 'preact/hooks';
import { route } from 'preact-router';
import { css } from '@emotion/css';

import { PageHeading } from '../../components/styled-components/typography';
import { FlexRowSpaceBetween, Space } from '../../components/styled-components/layout';
import { isActionAllowed } from '../../helpers/roles';
import { PERMISSIONS } from '../../constants';
import { AppContext, useAppContext } from '../../app-context/app-context';
import { ClientContext } from '../../graphql-context/graphql-context';
import { colors } from '../../style/style-variables';
import Button from '../../components/button';
import CreateLocationModal from './create-location-modal';
import RenameLocationModal from './rename-location-modal';
import MainContent from '../../components/main-content/main-content';
import useFetch from '../../hooks/use-fetch';
import ConfirmModal from '../../components/confirm-modal';
import Table from '../../components/table/Table';
import Dot from '../../components/dot/dot';
import LocationItemPopupMenu from './location-item-popup-menu';
import Tooltip from '../../components/tooltip/ToolTip';
import { SelectColumnFilter } from '../../components/table/Filters';

const queryGetLocations = gql`
  query GetLocations {
    getLocations {
      guid
      name
      screenType
      resolution
      interactivity
    }
  }
`;

const mutationEditLocation = gql`
  mutation EditLocation($guid: String!, $name: String!) {
    editLocation(guid: $guid, name: $name) {
      guid
      name
    }
  }
`;

const mutationDeleteLocation = gql`
  mutation deleteLocation($guid: String!) {
    deleteLocation(guid: $guid)
  }
`;

export const gridTemplate = '30px minmax(200px,300px) 120px 125px 1fr 50px';

const Locations = () => {
  const { user } = useContext(AppContext);
  const graphqlContext = useContext(ClientContext);
  const { setMenuVisible } = useAppContext();

  const [locations, setLocations] = useState({ count: 0, limit: 100, offset: 0, list: [] });
  const [visible, setVisible] = useState(false);
  const [renameLocationModalVisible, setRenameLocationModalVisible] = useState(false);
  const [currentlyViewedLocation, setCurrentlyViewedLocation] = useState(null);
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);

  const { data } = useFetch('/get-connected-agents');

  const reload = async () => {
    const result = await graphqlContext.client(queryGetLocations);
    setLocations({ list: result.getLocations });
  };

  const rename = async (name) => {
    const payload = {
      guid: currentlyViewedLocation.guid,
      name: name,
    };
    await graphqlContext.client(mutationEditLocation, payload);
    setRenameLocationModalVisible(false);
    reload();
  };

  const deleteLocation = async () => {
    console.log('currentlyViewedLocation: ', currentlyViewedLocation);
    const payload = {
      guid: currentlyViewedLocation.guid,
    };
    await graphqlContext.client(mutationDeleteLocation, payload);
    setConfirmDeleteModalVisible(false);
    reload();
  };

  const handleRenameClick = (guid) => {
    setCurrentlyViewedLocation(locations.list.find((location) => location.guid === guid));
    setRenameLocationModalVisible(true);
  };

  const handleCloseRenameModal = () => {
    setCurrentlyViewedLocation(null);
    setRenameLocationModalVisible(false);
  };

  const handleDeleteClick = (guid) => {
    setCurrentlyViewedLocation(locations.list.find((location) => location.guid === guid));
    setConfirmDeleteModalVisible(true);
  };

  const handleCloseDeleteModal = () => {
    setCurrentlyViewedLocation(null);
    setConfirmDeleteModalVisible(false);
  };

  const navigateToLocation = (guid) => {
    route(`/installations/${guid}`);
  };

  useEffect(() => {
    setMenuVisible(true);
    reload();
  }, []);

  const columns = [
    { Header: '', accessor: 'status', disableFilters: true },
    { Header: 'Name', accessor: 'name' },
    { Header: 'Type', accessor: 'type', Filter: SelectColumnFilter, filter: 'includes' },
    { Header: 'Resolution', accessor: 'resolution' },
    { Header: 'Interactivity', accessor: 'interactivity', Filter: SelectColumnFilter, filter: 'includes' },
    { Header: '', accessor: 'options', disableFilters: true },
  ];

  const tableData = locations.list.map(({ name, screenType, resolution, interactivity, guid }) => {
    const active = data?.some(({ location }) => location.guid === guid);

    return {
      guid,
      status: (
        <Tooltip content={active ? 'Active' : 'Error'} direction="right">
          <div style={{ position: 'relative', display: 'flex', alignItems: 'center'  }}>
            <Dot color={active ? colors.ok : colors.error} />
          </div>
        </Tooltip>
      ),
      name,
      type: screenType === 'projector' ? 'Projector' : screenType.toUpperCase(),
      resolution,
      interactivity: interactivity
        .split(',')
        .join(', ')
        .replace(/^\w/, (c) => c.toUpperCase()),
      options: (
        <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <LocationItemPopupMenu
            name={name}
            guid={guid}
            reload={reload}
            handleRenameClick={handleRenameClick}
            handleDeleteClick={handleDeleteClick}
          />
        </div>
      ),
    };
  });

  return (
    <MainContent>
      <FlexRowSpaceBetween>
        <PageHeading>Installations</PageHeading>
        {isActionAllowed(PERMISSIONS.CREATE_LOCATION, user?.role_id) && (
          <Button
            style={{
              padding: '8px 16px',
              fontSize: '14px',
            }}
            text="Create Installation"
            icon="add"
            onClick={() => setVisible(true)}
          />
        )}
      </FlexRowSpaceBetween>
      <Space />
      <Table
        data={tableData}
        columns={columns}
        onRowClick={(row) => navigateToLocation(row.original.guid)}
        rowClassName={css`
          cursor: pointer;
        `}
        sortable
      />
      <CreateLocationModal
        visible={visible}
        onClose={(done) => done && reload()}
        closeModal={() => setVisible(false)}
      />
      <RenameLocationModal
        visible={renameLocationModalVisible}
        closeModal={handleCloseRenameModal}
        location={currentlyViewedLocation}
        onSave={rename}
      />
      <ConfirmModal
        visible={confirmDeleteModalVisible}
        closeModal={handleCloseDeleteModal}
        title="Confirm deletion"
        confirmText={`Are you sure that you want to delete location?`}
        confirmButtonText="Delete"
        handleConfirm={deleteLocation}
        confirmButtonVariant={'delete'}
      />
    </MainContent>
  );
};

export default Locations;

export const getRoles = async () => {
  const response = await fetch('/get-roles', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });

  const data = await response.json();

  return data.roles;
};

import styled from '@emotion/styled';
import { route } from 'preact-router';
import { useContext, useState } from 'preact/hooks';
import ButtonComponent from '../../components/button';
import { Space } from '../../components/styled-components/layout';
import { AppContext } from '../../app-context/app-context';
import Confirm from './confirm/confirm';
import Login from './login/login';
import OldUser from './old-user/old-user';
import { colors } from '../../style/style-variables';
import ForceNewPassword from './newPassword';

const Container = styled.div`
    width: 100%;
    max-width: 620px;
    margin: auto;
    padding: 6rem;
    border-radius: 9px;
    background-color: ${colors['darkPurpleGray']};
`;

const componentsByState = {
    LOGIN: Login,
    NOT_CONFIRMED: Confirm,
    OLD_USER: OldUser,
    NEW_PASSWORD_REQUIRED: ForceNewPassword
};


const Public = () => {

    const [state, setState] = useState('LOGIN');
    const [session, setSession] = useState(undefined);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const { user } = useContext(AppContext);

    if (user) {
        route('/');
        // location.reload();
        // console.log('reloaded');
    }

    const Fallback = () => {
        return (
            <>
                <div>Error: No component to handle {state}</div>
                <Space size={1} />
                <ButtonComponent onClick={() => setState('LOGIN')}>Reset</ButtonComponent>
            </>
        );
    };

    const Component = componentsByState[state] || Fallback;

    const componentProps = {
        session,
        setSession,
        setState,
        email,
        setEmail,
        password,
        setPassword,
    };

    return (
        <Container>
            <Component { ...componentProps } />
        </Container>
    );
};

export default Public;

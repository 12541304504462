import styled from '@emotion/styled';
import { colors } from '../../style/style-variables';

const sizes = {
    small: '12px',
    default: '14px',
    medium: '16px',
};

export const PageHeading = styled.h1`
    color: ${ p => p.color ? colors[p.color] : colors['superLightGray']};
    font-family: roboto;
    font-size: 28px;
    font-weight: 400;
    ${p => p.marginBotton === 'small' ? 'margin-bottom: .25rem;' : ''}
    margin-top: 0;
`;

export const SectionHeading = styled.h3`
    color: ${ p => p.color ? colors[p.color] : 'white'};
    font-family: roboto;
    font-size: 18px;
    font-weight: 600;
    margin-top: 0;
`;

export const LogoText = styled.h3`
    color: white;
    font-family: roboto;
    font-size: 18px;
    font-weight: 400;
    margin: 0;
`;


export const Text = styled.span`
    color: ${props => colors[props.color] || '#ffffff'};
    font-family: roboto;
    font-size: ${props => sizes[props.size] || sizes.default};
    margin-top: 0;
`;

export const TextSmall = styled.span`
    color: ${props => props.color || '#ffffff'};
    font-family: roboto;
    font-size: 12px;
    font-weight: 300;
    margin-top: 0;
`;

export const ErrorMessage = styled.p`
    color: ${colors['error']};
    font-family: roboto;
    font-size: 16px;
`;

export const ErrorMessageForInput = styled(ErrorMessage)`
    bottom: 0;
    height: 17px;
    font-size: 0.8rem;
    margin:0;
`;

export const PopupMenuItem = styled.div`
    font-family: roboto;
    color: ${colors['superLightGray']};
    margin: 1rem 0;
    cursor: pointer;
    transition: color 0.2s ease;

    &:hover {
        color: white;
    }
`;
import Router, { route } from 'preact-router';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { colors } from '../../style/style-variables';
import { useAppContext } from '../../app-context/app-context';
import { isRoleAllowed } from '../../helpers/roles';
import { ROLES } from '../../constants';
import Menu from '../menu';
import Locations from '../locations';
import Location from '../location/location';
import Playlists from '../playlists';
import Files from '../files';
import Content from '../content';
import EditPlaylist from '../playlists/edit-playlist';
import Public from '../public/public';
import Header from '../header/header';
import Four04 from '../404/404';
import Management from '../management';
import ForgotPassword from '../public/ForgotPassword';
import ConfirmForgotPassword from '../public/ConfirmForgotPassword';
import { ErrorBoundary } from '../../components/auth';
import { ErrorMessage } from '../../components/auth/ErrorMessage';

const StyledVersion = styled.div`
  bottom: 20px;
  left: 20px;
  color: #ffffff33;
  position: fixed;
`;

const globalCss = css`
  * {
    box-sizing: border-box;
    font-family: 'Roboto';
  }

  /*
        Below are some needed global styles for the calendar, as the calendar overlay
        is not rendered as a child of the react big calendar that we have modified the
        style of.
    */
  .rbc-overlay {
    background-color: #363b4a;
    border-radius: 6px;
    padding: 1rem;
    border: none;
  }

  .rbc-overlay-header {
    border: none;
  }

  .rbc-event {
    background-color: #6b7a99;
  }
`;

const publicRoutes = ['/login', '/forgot-password', '/confirm-forgot-password'];

const queryCache = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 30000,
    },
  },
});

const App = ({ appInfo }) => {
  const all = useAppContext();
  const { user } = useAppContext();

  const onRouteChange = (event) => {
    // dont allow logged out users to access secret stuff
    if (!user && !publicRoutes.includes(event.url)) {
      route('/login');
    }
  };

  return (
    <>
      <QueryClientProvider client={queryCache}>
        <Global styles={globalCss} />
        <StyledVersion>{appInfo.version}</StyledVersion>
        <Menu />
        <Content>
          <Header />
          <ErrorBoundary fallbackComponent={<ErrorMessage />}>
            <Router onChange={onRouteChange}>
              <Locations path="/" />
              <Locations path="/installations" />
              <Location path="/installations/:guid" />
              <Playlists path="/playlists" />
              <EditPlaylist path="/playlist/:guid" />
              {isRoleAllowed(user?.role_id, ROLES.ADMIN, ROLES.ORGANIZER) && <Management path="/management" />}
              <Files path="/content" />
              <Four04 default />
              <Public path="/login" />
              <ForgotPassword path="/forgot-password" />
              <ConfirmForgotPassword path="/confirm-forgot-password" />
            </Router>
          </ErrorBoundary>
        </Content>
      </QueryClientProvider>
    </>
  );
};

export default App;

import { createContext } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';
import { GraphQLClient } from 'graphql-request';

const fetch = async (query, variables, graphqlContext) => {
    const result = await graphqlContext.client(query, variables);
    return result;
};


const useQuery = (query, variables) => {

    const graphqlContext = useContext(ClientContext);

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fire = async (variables) => {
        try {
            setError(false);
            setLoading(true);
            const result = await graphqlContext.client(query, variables);
            setData(result);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setError(err);
        }
    }

    useEffect(() => {
        fire(variables);
    }, []);

    /**
     * 
     * @param {Object} [variablesOverride] optional object that can override initial variables
     */
    const refetch = (variablesOverride) => {
        fire(variablesOverride || variables);
    };

    return { data, loading, error, refetch };

};

/**
 * @callback onCompletedCallback
 * @param {Object} data mutation result data
 */

/**
 * @callback onErrorCallback
 * @param {Object} error error object
 */

/**
 * @param {string} mutation a graphql mutation string
 * @param {Object} options options object
 * @param {Object} options.variables variables (if not supplied when calling mutation)
 * @param {onCompletedCallback} options.onCompleted
 * @param {onErrorCallback} options.onError
 * @returns {Array} Array, on index 0 is the mutation trigger, index 1 is an Object with loading, error and data
 */
const useMutation = (mutation, options) => {

    const graphqlContext = useContext(ClientContext);

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const trigger = async (variables) => {
        try {
            setError(null);
            setLoading(true);
            // variable priority: 1. passed to trigger function 2. passed as hook options
            const result = await graphqlContext.client(mutation, variables || options.variables || {});
            setLoading(false);
            setData(result);
            setError(null);
            if (typeof options.onCompleted === 'function') {
                options.onCompleted(result);
            }
        } catch (error) {
            setLoading(false);
            setError(error);
            if (typeof options?.onError === 'function') {
                options.onError(error);
            }
        }
    };

    return [trigger, { data, error, loading }];

};

const ClientContext = createContext({ fetch: () => {}, client: async () => {} });

export {
    ClientContext,
    useQuery,
    fetch,
    useMutation,
};




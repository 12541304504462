import { useState } from 'preact/hooks';
import axios from 'axios';
import ButtonComponent from '../../../components/button';
import { Input } from '../../../components/form';
import { Space } from '../../../components/styled-components/layout';
import { SectionHeading, Text } from '../../../components/styled-components/typography';
import { getErrorMessage } from '../../../helpers/get-error-message';

const OldUser = ({ setState, email }) => {

    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [error, setError] = useState(null);

    const codeInputProps = {
        value: code,
        label: 'Confirmation Code (check your email)',
        onChange: (event) => setCode(event.target.value),
    };

    const passwordProps = {
        value: password,
        label: 'Password',
        type: 'password',
        onChange: (event) => setPassword(event.target.value),
    };

    const repeatPasswordProps = {
        value: repeatPassword,
        label: 'Repeat Password',
        type: 'password',
        onChange: (event) => setRepeatPassword(event.target.value),
    };

    const buttonProps = {
        onClick: async () => {

            try {

                setLoading(true);

                const { data } = await axios.post("/activate-old-user", {
                    email,
                    password,
                    code,
                })

                setLoading(false);

                if (data.status === 'OK') {
                    location.reload();
                }

            } catch (error) {
                setLoading(false);
                setError(getErrorMessage(error));
            }

        },
        loading,
        disabled: !code || !password || password !== repeatPassword,
    };

    return (
        <>
            <SectionHeading>Choose a new password</SectionHeading>
            <Input { ...codeInputProps } />
            <Input { ...passwordProps } />
            <Input { ...repeatPasswordProps } />
            <Space size={1} />
            {error && (
                <>
                <Space size={1} />
                <Text color="error">{error}</Text>
                </>
            )}
            <ButtonComponent { ...buttonProps }>Activate</ButtonComponent>
        </>
    );
};

export default OldUser;

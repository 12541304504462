import styled from '@emotion/styled';
import { colors } from '../../style/style-variables';

const Container = styled.section`
    border: 1px solid ${colors.blue};
    border-radius: 3px;
    display: inline-block;
    padding: 8px 0;
`;

const Option = styled.span`
    padding: 8px 24px;
    color: ${colors.superLightGray};
    cursor: pointer;
    background-color: ${props => props.active ? colors.blue : 'inherit'};
    transition: background-color 0.2s ease;
`;

export const Switch = ({ value, options, onChange }) => {

    return (
        <Container>
            {
                options.map(option => {

                    const optionsProps = {
                        onClick: () => onChange(option.value),
                        active: value === option.value,
                    };

                    return (
                        <Option { ...optionsProps } key={option.value}>
                            {option.label}
                        </Option>
                    );
                })
            }
        </Container>
    );
};


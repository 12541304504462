import { gql } from 'graphql-request';

export const getAssets = gql`
    query GetAssets {
        getAssets {
            guid
            filename
            key
            thumbnail
            size
            name
        }
    }
`;

export const getPlaylist = gql`
    query GetPlaylist ($guid: String!) {
        getPlaylist (guid: $guid) {
            name
            screenType
        }
    }
`;


export const getPlaylistItems = gql`
    query GetPlaylistItems ($playlistGuid: String!) {
        getPlaylistItems (playlistGuid: $playlistGuid) {
            start
            guid
            end
            sound
            asset {
                name
                thumbnail
                filename
                size
                guid
            }
        }
    }
`;

export const createPlaylistItem = gql`
    mutation CreatePlaylistItem(
        $assetGuid: String!,
        $sound: Boolean!,
        $start: String!,
        $end: String!,
        $playlistGuid: String! ) {
        createPlaylistItem( playlistGuid: $playlistGuid, input: { assetGuid: $assetGuid, sound: $sound, start: $start, end: $end } ) {
            guid
        }
    }
`;

export const editPlaylistItem = gql`
    mutation EditPlaylistItem(
        $assetGuid: String!,
        $sound: Boolean!,
        $start: String!,
        $end: String!,
        $playlistItemGuid: String!
    ) {
        editPlaylistItem(
            guid: $playlistItemGuid, input: { assetGuid: $assetGuid, start: $start, end: $end, sound: $sound }
        ) {
            guid
        }
    }
`;

export const editPlaylist = gql`

    mutation EditPlaylist(
        $guid: String!,
        $name: String!,
        $items: [PlaylistItemInput!]!,
    ) {
        editPlaylist(
            guid: $guid, name: $name, items: $items
        ) {
            guid
        }
    }
`;

export const editPlaylistInfo = gql`

    mutation EditPlaylistInfo(
        $guid: String!,
        $name: String!,
        $screenType: String!,
    ) {
        editPlaylistInfo(
            guid: $guid, name: $name, screenType: $screenType
        ) {
            guid
        }
    }
`;

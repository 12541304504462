export const colors = {
    // how many shades are these? we must be approaching 50...
    superLightGray: '#d2d2d2',
    lightGray: '#b1b1b1',
    lightMediumGray: '#6f6f6f',
    mediumGray: '#3a3b4d',
    greenishDarkGray: '#363b4a',
    darkGray: '#272731',
    darkestGray: '#20252e',
    lightPurpleGray: '#3a3a48',
    darkPurpleGray: '#2a2e3b',
    blue: '#4d5eab',
    transparentLightBlueGray: 'rgba(108, 123, 153, 0.5)',
    dullRed: '#d02b04',
    white: '#ffffff',
    darkRed: '#612f2f',
    black: '#000000',
    lightBlack: '#0d0f12',
    error: '#bd5b5b',
    ok: '#51db53'
};

export const sizes = {
    sidebarWidth: '400px',
    menuWidth: '236px',
    editPlaylistSidebarWidth: '700px',
};

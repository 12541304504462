import axios from 'axios';
import { useState } from 'preact/hooks';
import ButtonComponent from '../../../components/button';
import { Input } from '../../../components/form';
import { Row, Space } from '../../../components/styled-components/layout';
import { SectionHeading, Text, TextSmall } from '../../../components/styled-components/typography';
import { getErrorMessage } from '../../../helpers/get-error-message';

const Confirm = ({ setState, email, password }) => {

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState('');

    const codeInputProps = {
        value: code,
        label: 'Code',
        onChange: event => setCode(event.target.value),
    };

    const confirmButtonProps = {
        onClick: async () => {

            try {
                setLoading(true);
                await axios.post("/confirm", {
                    code,
                    email,
                    password,
                });

                setLoading(false);
                setState("LOGIN");
            } catch (error) {
                setError(getErrorMessage(error));
                setLoading(false);
            }
        },
        loading,
    };

    const resendCodeButtonProps = {
        onClick: async () => {
            try {
                setLoading(true);
                const response = await fetch(`/resend-verification-code/${email}`);
                if (response.ok) {
                    setLoading(false);
                }
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        },
        loading,
    };


    return (
        <>
            <SectionHeading>You need to confirm your account</SectionHeading>
            <TextSmall>Check your inbox for a confirmation code</TextSmall>
            <Input { ...codeInputProps } />
            <Row justifyContent="space-between">
                <ButtonComponent { ...confirmButtonProps}>Confirm</ButtonComponent>
                <ButtonComponent { ...resendCodeButtonProps }>Resend Code</ButtonComponent>
            </Row>
            { error && (
                <>
                <Space size={1} />
                <Text color="error">{error}</Text>
                </>
            )}
        </>
    );
};

export default Confirm;

import styled from '@emotion/styled';

export const AnimatedContainer = styled.div`
    height: ${props => props.height || 'initial'};
    transition: height 0.3s ease;
    overflow: hidden;
    margin-top: 1rem;
`;

export const StyledInfoContainer = styled.div`
    padding-left: 1rem;
`;

export const StyledSpinner = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
`;

export const StyledGuid = styled.div`
`;

export const StyledTop = styled.div`
    padding-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StyledTitleTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
`;



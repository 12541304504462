import styled from '@emotion/styled';
import { colors } from '../../style/style-variables';
import { SectionHeading } from '../../components/styled-components/typography';

const ModalBackgroundCover = styled.div`
    background-color: black;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: ${ p => p.relativeZindex ? (1000+p.relativeZindex).toString()  : '1000' };
`;

const Modal = styled.div`
    background-color: ${colors['darkPurpleGray']};
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: ${ p => p.relativeZindex ? (1001+p.relativeZindex).toString()  : '1001' };
    border-radius: 20px;
    padding: 2rem;
    width: 50vw;
    max-width: 670px;
    box-shadow: 0px 4px 18px rgb(0 0 0 / 30%);
`;

const ModalComponent = ({ visible, children, closeModal, title, relativeZindex }) => (
    visible ? (
        <>
            <Modal relativeZindex={relativeZindex}>
                { title ? <SectionHeading>{title}</SectionHeading> : null }
                { children }
            </Modal>
            <ModalBackgroundCover relativeZindex={relativeZindex} onClick={closeModal} />
        </>
    ) : null
);

export default ModalComponent;

import { gql } from 'graphql-request';
import { useState } from 'preact/hooks';
import { FlexRowEnd, Space, HorizontalSpace, FlexRow } from '../../../components/styled-components/layout';
import Modal from '../../../components/modal';
import { Input } from '../../../components/form';
import { Select } from '../../../components/form';
import Button from '../../../components/button';
import Checkbox from '../../../components/checkbox/checkbox';
import { useMutation } from '../../../graphql-context/graphql-context';
import screenTypes from '../../../helpers/screen-types';
import { Label } from '../../../components/styled-components/forms';
import { Text } from '../../../components/styled-components/typography';
import { route } from 'preact-router';

const mutationCreatePlaylist = gql`
    mutation CreatePlaylist(
        $name: String!
        $screenType: String!
        $interactivity: String!
    ) {
        createPlaylist(
            name: $name
            screenType: $screenType
            interactivity: $interactivity
        ) {
            guid
            screenType
        }
    }
`;

const CreatePlaylistModal = ({ visible, closeModal}) => {

    const [formValues, setFormValues] = useState({
        interactivity: ['none'],
    });

    const [createPlaylist, { loading }] = useMutation(mutationCreatePlaylist, {
        onCompleted: (data) => {
            route(`/playlist/${data.createPlaylist.guid}`);
        },
    });

    const submitForm = () => {
        const variables = {
            ...formValues,
            interactivity: formValues.interactivity.join(','),
        };
        createPlaylist(variables);
    };

    const noneCheckboxProps = {
        checked: formValues.interactivity?.includes('none'),
        onChange: (event) => {
            if (event.target.checked) {
                setFormValues({
                    ...formValues,
                    interactivity: ['none'],
                });
            } else {
                setFormValues({
                    ...formValues,
                    interactivity: ['touch'],
                });
            }
        },
    };

    const touchCheckboxProps = {
        checked: formValues.interactivity?.includes('touch'),
        onChange: event => {
            if (event.target.checked) {
                setFormValues({
                    ...formValues,
                    interactivity: [ ...formValues.interactivity.filter(i => i !== 'none'), 'touch' ],
                });
            } else {
                const newValues = formValues.interactivity.filter(i => i !== 'touch');
                setFormValues({
                    ...formValues,
                    interactivity: newValues.length ? newValues : ['none'],
                });
            }
        }
    };

    const motionCheckboxProps = {
        checked: formValues.interactivity?.includes('motion'),
        onChange: event => {
            if (event.target.checked) {
                setFormValues({
                    ...formValues,
                    interactivity: [ ...formValues.interactivity.filter(i => i !== 'none'), 'motion' ],
                });
            } else {
                const newValues = formValues.interactivity.filter(i => i !== 'motion');
                setFormValues({
                    ...formValues,
                    interactivity: newValues.length ? newValues : ['none'],
                });
            }
        }
    };
    const disabled = !(formValues['name'] && formValues['screenType']);

    return (
        <Modal
            visible={visible}
            closeModal={closeModal}
            title={'Create Playlist'}
        >
            <Input
                label="Name"
                value={formValues['name']}
                onChange={ e => setFormValues({ ...formValues, ...{ name: e.target.value }})}
            />
            <Select
                label="Screen type"
                value={formValues['screenType']}
                onChange={ e => setFormValues({ ...formValues, ...{ screenType: e.target.value }})}
                options={screenTypes}
                placeholder="Select screen type"
                maxWidth="250px"
            />
            <Label>
                Interactivity
            </Label>
            <FlexRow>
                <Text>None:</Text> <Checkbox { ...noneCheckboxProps } />
                <Text>Touch:</Text> <Checkbox { ...touchCheckboxProps } />
                <Text>Motion:</Text> <Checkbox { ...motionCheckboxProps } />
            </FlexRow>
            <Space />
            <FlexRowEnd>
                <Button type="text" text="Cancel" onClick={closeModal} />
                <HorizontalSpace />
                <Button text="Create" onClick={submitForm} disabled={disabled} loading={loading} />
            </FlexRowEnd>
        </Modal>
    );
};

export default CreatePlaylistModal;

import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  getOrganizations,
  getOrganization,
  createOrganization,
  deleteOrganization,
  editOrganization,
} from '../api';

export const ORGANIZATIONS_STATE_KEY = 'organizations';

export const useGetOrganizations = (isAllowedToGetOrganizations = true) => {
  return useQuery(ORGANIZATIONS_STATE_KEY, getOrganizations, {
    enabled: isAllowedToGetOrganizations,
  });
};

export const useGetOrganization = (uuid) =>
  useQuery(['organization', uuid], () => getOrganization(uuid));

export const useCreateOrganization = () => {
  const cache = useQueryClient();

  return useMutation(createOrganization, {
    onSuccess: () => {
      cache.invalidateQueries(ORGANIZATIONS_STATE_KEY);
    },
  });
};
export const useEditOrganization = () => {
  const cache = useQueryClient();

  return useMutation(editOrganization, {
    onSuccess: () => {
      cache.invalidateQueries(ORGANIZATIONS_STATE_KEY);
    },
  });
};

export const useDeleteOrganization = () => {
  const cache = useQueryClient();

  return useMutation(deleteOrganization, {
    onSuccess: () => {
      cache.invalidateQueries(ORGANIZATIONS_STATE_KEY);
    },
  });
};

import styled from '@emotion/styled';
import { colors } from '../../style/style-variables';

const StyleContent = styled.div`
    background-color: ${colors.darkestGray};
    height: 100%,
    flex: 1;
    boxSizing: border-box;
    width: 100%;
    min-Height: 100vh;
    display: flex;
`;

const Content = ({ children }) => {
    return (
        <StyleContent>
            {children}
        </StyleContent>
    );
};

export default Content;



import { useMemo, useState } from 'preact/hooks';
import ButtonComponent from '../button';
import Checkbox from '../checkbox/checkbox';
import Icons from '../icons';
import { Input, Select } from '../form';
import Popover from '../popover/popover';
import { PopupMenuItem } from '../styled-components/typography';

export const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id, Header } }) => {
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <Select
      label={Header}
      value={filterValue}
      options={[
        { key: undefined, name: 'All' },
        ...options.map((option, i) => ({
          key: option,
          name: option,
        })),
      ]}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    />
  );
};

export const DropDownColumnFilter = ({
  multiMode = true,
  column: { filterValue = [], setFilter, preFilteredRows, id, Header },
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const options = useMemo(() => {
    let counts = {};
    preFilteredRows.forEach((x) => {
      x = x.values[id].toString();

      counts[x] = (counts[x] || 0) + 1;
    });
    return counts;
  }, [id, preFilteredRows]);

  const handleSetFilter = (option) => {
    if (!multiMode) {
      setFilter((state = []) => (state.includes(option) ? [] : [option]));
    }

    if (multiMode) {
      if (filterValue.includes(option)) {
        setFilter(filterValue.filter((v) => v !== option));
      } else {
        setFilter([...filterValue, option]);
      }
    }
  };

  const popoverContent = (
    <div>
      {Object.entries(options).map(([option, count]) => (
        <PopupMenuItem
          key={count}
          onClick={() => {
            handleSetFilter(option);
          }}
        >
          {option} <Checkbox checked={filterValue.includes(option)} />
        </PopupMenuItem>
      ))}
    </div>
  );

  const popoverProps = {
    visible: popoverOpen,
    content: popoverContent,
    onClose: () => {
      setPopoverOpen(false);
    },
  };

  return (
    <Popover {...popoverProps}>
      <ButtonComponent
        text={Header}
        onClick={() => {
          setPopoverOpen(!popoverOpen);
        }}
      >
        <Icons name={popoverOpen ? 'chevron-up' : 'chevron-down'}></Icons>
      </ButtonComponent>
    </Popover>
  );
};

export const DefaultFilterForColumn = ({ column: { filterValue, setFilter, Header } }) => {
  return (
    <Input
      label={Header}
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    />
  );
};

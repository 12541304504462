import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import { useState } from 'preact/hooks';
import styled from '@emotion/styled';
import { Input } from '../../components/form';
import { SectionHeading, Text } from '../../components/styled-components/typography';
import { colors } from '../../style/style-variables';
import ButtonComponent from '../../components/button';
import { Space } from '../../components/styled-components/layout';
import { route } from 'preact-router';
import { getErrorMessage } from '../../helpers/get-error-message';

const Container = styled.div`
  width: 100%;
  max-width: 620px;
  margin: auto;
  padding: 6rem;
  border-radius: 9px;
  background-color: ${colors['darkPurpleGray']};
`;

const ConfirmForgotPassword = () => {
  const [error, setError] = useState(null);
  const {
    control,
    handleSubmit,
    reset,
    clearErrors,
    getValues,
    resetField,
    setError: setFormError,
    formState: { errors },
  } = useForm();

  const formSubmit = async ({ email, code, password, confirmPassword }) => {
    if (confirmPassword !== password) {
      setFormError('confirmPassword', { type: 'validate', message: 'Please make sure your passwords match.' });
      resetField('password');
      resetField('newPassword');
      return;
    }

    try {
      await axios.post('/confirm-forgot-password', {
        email: email.toLowerCase(),
        code,
        password,
      });

      route('/login');
      reset();
      clearErrors();
    } catch (error) {
      setError(getErrorMessage(error));
    }
  };

  return (
    <Container>
      <SectionHeading>Confirm Forgot password</SectionHeading>
      <form onSubmit={handleSubmit(formSubmit)}>
        <Controller
          control={control}
          rules={{ required: 'Email address is required' }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              errorMsg={errors.email?.message}
              type="email"
              label="Email"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            ></Input>
          )}
          name="email"
        />
        <Controller
          control={control}
          rules={{ required: 'Code is required' }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              errorMsg={errors.code?.message}
              label="Code"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            ></Input>
          )}
          name="code"
        />
        <Controller
          control={control}
          rules={{
            required: 'New password is required',
            minLength: { value: 6, message: 'Minimum length on your password is 6 characters.' },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              errorMsg={errors.password?.message}
              type="password"
              label="New password"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            ></Input>
          )}
          name="password"
        />
        <Controller
          control={control}
          rules={{
            required: 'Confirm new password is required',
            validate: (value) => value === getValues('password') || 'Please make sure your passwords match.',
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              errorMsg={errors.confirmPassword?.message}
              type="password"
              label="Confirm new password"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            ></Input>
          )}
          name="confirmPassword"
        />
        {error && (
          <>
            <Space size={1} />
            <Text color="error">{error}</Text>
          </>
        )}
        <Space size={1}></Space>
        <ButtonComponent type="submit">Update Password</ButtonComponent>
      </form>
    </Container>
  );
};

export default ConfirmForgotPassword;

import styled from '@emotion/styled';
import Icons from '../icons';
import { colors } from '../../style/style-variables';
import { Spinner } from '../spinner/spinner';
import { Fragment } from 'preact';

const Button = styled.button`
    background-color: ${colors['blue']};
    border-radius: 24px;
    padding: 12px 36px;
    border: 0;
    color: white;
    font-family: 'Roboto';
    font-size: 0.8rem;
    text-decoration: none;
    transition: background-color 0.3s ease;
    font-weight: 300;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: #6a6ea5;
    }

    &:focus {
        outline: 0;
    }

    &:active {
        background-color: white;
    }

    &:disabled {
        background-color: rgba(77, 94, 171, 0.38);
        cursor: not-allowed;
    }

    &:disabled:hover {
        background-color: rgba(77, 94, 171, 0.38);
    }

`;

const buttonVariants = {
    primary: styled(Button)`
    `,
    text: styled(Button)`
        background-color: transparent;
    `,
    outlined: styled(Button)`
        background-color: transparent;
        border: 1px solid ${colors['blue']};
    `,
    delete: styled(Button)`
        background-color: ${colors.error};
        &:hover {
            background-color: #bb6c6c;
        }
    `,
    small: styled(Button)`
        padding: 4px 12px;
    `,
    ['small-outlined']: styled(Button)`
        padding: 4px 12px;
        background-color: transparent;
        border: 1px solid ${colors['blue']};
        font-size: 0.75rem;
    `,
}

const iconStyle = {
    fill: 'white',
    height: '24px',
    width: '24px',
    marginRight: '1rem',
    color: colors['superLightGray']
};

const ButtonComponent = ({ text, icon, onClick, theme, disabled, children, style = {}, loading, type="button" }) => {

    const Button = buttonVariants[ theme ? theme : 'primary' ];

    const Wrapper = loading ? Spinner : Fragment;

    return (
        <Wrapper size={20}>
            <Button onClick={onClick} disabled={disabled || loading} type={type} style={style}>
                { icon ? <Icons name={icon} style={iconStyle} /> : null }
                { text && text.toUpperCase() }
                { children }
            </Button>
        </Wrapper>
    );

};

export default ButtonComponent;

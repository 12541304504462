import { useState } from "preact/hooks";
import { ErrorMessage } from "../../components/styled-components/typography";
import Modal from '../../components/modal';
import { FlexRow, FlexRowEnd, HorizontalSpace, Space } from '../../components/styled-components/layout';
import { Input } from '../../components/form';
import Button from '../../components/button';

const RenameLocationModal = ({
    visible,
    closeModal,
    location,
    error,
    mutationLoading,
    onSave,
}) => {
    const [ inputValue, setInputValue ] = useState(location?.name || '');

    return (
        <Modal
            visible={visible}
            closeModal={closeModal}
            title={'Rename'}
        >
            <FlexRow>
                <Input
                    label="Name"
                    value={inputValue}
                    onChange={e=>setInputValue(e.target.value)}
                />
            </FlexRow>
            <Space />
            { error ? <ErrorMessage>{error}</ErrorMessage> : null }
            <FlexRowEnd>
                <Button type="text" text="Cancel" onClick={closeModal} disabled={mutationLoading} />
                <HorizontalSpace />
                <Button
                    text="Save"
                    onClick={() => onSave(inputValue)}
                    disabled={inputValue.length ? false : true}
                    loading={mutationLoading}
                />
            </FlexRowEnd>
        </Modal>
    );

};

export default RenameLocationModal;